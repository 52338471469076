

import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import georef from '../service/goref-service';

const CustomSelectMunicipio = ({ field, form, onMunicipioChange,provincia,valueProv,setValueMun, ...props }) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    console.log("paso por aca provincia")
    console.log(valueProv?.label)
    const fetchOptions = async () => {
      try {
        const response = await georef.getmunicipiosByProvincias(valueProv?.label);
        const data = response.data.municipios;
        console.log(data)
        // Transforma los datos de tu API en el formato requerido por react-select
        const formattedOptions = data.map((municipio) => ({
          value: municipio.id,
          label: municipio.nombre,
        }));

        setOptions(formattedOptions);
      } catch (error) {
        console.error('Error al obtener las provincias', error);
      }
    };

    fetchOptions();
  }, [valueProv]);
    //console.log(provincia)
    const fetchOptions = async (prov,muni) => {
      console.log(prov.label)
      console.log("muni "+muni)
      try {
        
        const response = await georef.getMunicipiosByProvincia(prov.label,muni);
        const data = response.data.municipios;
        console.log(data)
        // Transforma los datos de tu API en el formato requerido por react-select
        const formattedOptions = data.map((muni) => ({
          value: muni.id,
          label: muni.nombre,
        }));

        setOptions(formattedOptions);
      } catch (error) {
        console.error('Error al obtener municipios', error);
      }
    };

   
  

    const handleChange = (selectedOption) => {
    form.setFieldValue(field.name, selectedOption);
    onMunicipioChange(selectedOption);
    console.log(selectedOption)
  };
  const handleInputChange = (value) => {
    setInputValue(value);

    // Aquí puedes acceder al valor a medida que se escribe
    console.log('Valor a medida que se escribe:', value);
    fetchOptions(provincia,value);
  };
  
  const selectedOption1 = options.find(
    (option) => option.label === field.value?.label
  );
  if (setValueMun) {
    // Solo llamamos setValueMun si está definido como una función
    setValueMun(selectedOption1);
  }
  return (
    <Select
      {...props}
      options={options}
      value={selectedOption1} // Usa el valor del campo del formulario como valor seleccionado
      onChange={handleChange}
      onInputChange={handleInputChange}
      inputValue={inputValue}
      
    />
  );
};

export default CustomSelectMunicipio;