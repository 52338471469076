import React, { useEffect, useState } from 'react';
//import Form from 'react-bootstrap/Form';
import georef from '../service/goref-service';
import paises from "../assest/countriesV3.json"
import { Button, Container, Row, Col } from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import CustomSelect from './CustomSelect';
import CustomSelectMunicipio from './CusromSelectMunicipio';
import CustomSelectLocalidad from "./CustomSelectLocalidad";
import CustomSelectNacionalidad from "./CustomSelectNacionalidad"
import cflService from '../service/cfl-service';
import Swal from 'sweetalert2';

const FormCrearAlumno = ({initialValues}) => {
    console.log(initialValues)
    const [provincias, setProvincias] = useState([])
    const [municipios, setMunicipios] = useState([])
    const [pais, setPais] = useState([])
    const [localidad, setLocalidad] = useState([])
    const [alumno, setAlumno] = useState({});
    const [loading,setLoading]=useState(false)

    const asterico = <span id="i4" className="rAXpye" aria-label="Pregunta obligatoria">*</span>
    const navigate=useNavigate();
   
    const handleProvinceChange = (selectedOption) => {
        setProvincias(selectedOption);
        // Realiza la consulta de localidades utilizando el nombre de la provincia
        if (selectedOption) {

        } else {
            // Si no se selecciona ninguna provincia, puedes borrar el resultado anterior si es necesario
            setMunicipios([]);
        }
    };

    const handleMunicipioChange = async (selectedOption) => {
        setMunicipios(selectedOption);
        // Realiza la consulta de localidades utilizando el nombre de la provincia
        if (selectedOption) {
            const selectedMunicipioName = selectedOption.label;
            console.log(selectedMunicipioName)
            try {
                const respuesta = await georef.getLocalidadByMunicipio(selectedMunicipioName, provincias.label)
                const data = respuesta.data.localidades;
                const options = data.map((loc, i) => ({
                    value: i,
                    label: loc.nombre,
                }));

                console.log(options)
                setLocalidad(options)
            }
            catch (error) {
                console.error('Error al obtener los municipios', error);
            }


        }
        else {
            // Si no se selecciona ninguna provincia, puedes borrar el resultado anterior si es necesario
            setMunicipios([]);
        }
    };

    useEffect(() => {
        const data = paises
        const options = data.map((loc, i) => ({
            value: i,
            label: loc.translations.spa.common,
        }));
        console.log(options)
        setPais(options)
    }, [])

    const handleSubmit = (values) => {
        setLoading(true)
        // Aquí puedes manejar la lógica de envío del formulario
        console.log(values);
        const data = {
            apellido: values.apellido,
            calle: values.calle,
            dni: values.dni,
            email: values.email,
            fechaNacimiento:values.fechaNacimiento,
            idAlumnos: 0,
            localidad: values.localidad.label,
            lugar_nacimiento: values.lugar_nacimiento,
            nacionalidad: values.nacionalidad.label,
            nombre: values.nombre,
            numero: values.numero?values.numero:null,
            partido: values.municipio.label,
            telefono: values.telefono?values.telefono:null,
            sexo:values.sexo,
            provincia:values.provincia.label,
        }
        console.log(`La data es : ${JSON.stringify(data)}`)
        cflService.crearAlumno(data)
        .then((resp)=>{
            setLoading(false)
            if(resp.data.success){
                Swal.fire({
                    title:'Exito!',
                    text:`Se registro con exito`,
                    icon:"success",
                    showConfirmButton:false,
                    showCloseButton:true
                })
                .then((resp)=>{
                    navigate("/alumnos")
                })
                
            }
            else{
                Swal.fire({
                    title:'Cuidado!',
                    text:`El numero de documento ya esta exite!`,
                    icon:"warning",
                    showConfirmButton:false,
                    showCloseButton:true
                })

            }
            console.log(resp)
        })
        .catch((error)=>{
            setLoading(false)
            Swal.fire({
                title:'Cuidado!',
                text:`Revise su conexion!`,
                icon:"error",
                showConfirmButton:false,
                showCloseButton:true
            })
        })
    };
    const validationSchema = yup.object().shape({
        nombre: yup.string().required("Nombre es requerido"),
        apellido: yup.string().required("Apellido es requerido"),
        dni: yup.number().required("DNI es requerido"),
        sexo: yup.string().required("Sexo es requerido").oneOf(["F", "M", "X"]),
        fechaNacimiento: yup.date().required("Fecha de nacimiento es requerido"),
        telefono: yup.number("Telefono sin guines solo números"),
        email: yup.string().required("Email es reaquerido es requerido").email("verifica que sea un email correcto"),
        numero: yup.string(),
        calle: yup.string().required("Calle es requerido"),
        provincia: yup.object().nullable().required('Debes seleccionar una provincia'),
        municipio: yup.object().nullable().required('Debes seleccionar un municipio'),
        localidad: yup.object().nullable().required('Debes seleccionar una localidad'),
        nacionalidad: yup.object().nullable().required('Debes seleccionar una nacionalidad'),
        lugar_nacimiento: yup.string(),
    });

    return (
        <Container fluid className='' style={{ width: '100% !important' }}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ touched, errors }) => (
                    <Form>
                        <Row className='mt-4'>
                            <Col md={6}>
                                <label htmlFor='nombre' className='form-label'>Nombre{asterico}</label>
                                <Field
                                    type="text"
                                    placeholder="Ingrese el nombre"
                                    id="nombre"
                                    name='nombre'
                                    className={`form-control ${touched.nombre && errors.nombre ? 'is-invalid' : touched.nombre && !errors.nombre ? 'is-valid' : ''
                                        }`}
                                />
                                <ErrorMessage
                                    name="nombre"
                                    component="div"
                                    className="text-danger"
                                />
                            </Col>
                            <Col md={6}>
                                <label htmlFor='apellido' className='form-label'>Apellido{asterico}</label>
                                <Field
                                    type="text"
                                    placeholder="Ingrese el apellido"
                                    id="apellido"
                                    name='apellido'
                                    className={`form-control ${touched.apellido && errors.apellido ? 'is-invalid' : touched.apellido && !errors.apellido ? 'is-valid' : ''
                                        }`}
                                />
                                <ErrorMessage
                                    name="apellido"
                                    component="div"
                                    className="text-danger"
                                />
                            </Col>
                        </Row>
                        <Row className='mt-2 pt-2' >
                            <Col md={4}>
                                <label htmlFor='dni' className='form-label'>DNI{asterico}</label>
                                <Field
                                    type="number"
                                    placeholder="Ingrese el dni"
                                    id="dni"
                                    name='dni'
                                    className={`form-control ${touched.dni && errors.dni ? 'is-invalid' : touched.dni && !errors.dni ? 'is-valid' : ''
                                        }`}
                                />
                                <ErrorMessage
                                    name="dni"
                                    component="div"
                                    className="text-danger"
                                />
                            </Col>
                            <Col md={4}>
                            <label htmlFor="sexo" className="form-label" >
                                Sexo{asterico}
                            </label>
                            <Field
                                as="select" // Utilizamos "as" para indicar que es un select
                                id="sexo"
                                name="sexo"
                                className={`form-select ${touched.sexo && errors.sexo ? 'is-invalid' : touched.sexo && !errors.sexo ? 'is-valid' : ''
                                    }`}
                            >
                                <option value="" disabled>
                                    Selecciona tu sexo
                                </option>
                                <option value="M">M (Masculino)</option>
                                <option value="F">F (Femenino)</option>
                                <option value="X">X</option>
                            </Field>
                            <ErrorMessage
                                name="sexo"
                                component="div"
                                className="text-danger"
                            />
                            </Col>
                            <Col md={4}>
                            <label htmlFor='fechaNacimiento' className='form-label'>Fecha de Nacimiento{asterico}</label>
                            <Field
                                type="date"
                                placeholder="Ingrese el fechaNacimiento"
                                id="fechaNacimiento"
                                name='fechaNacimiento'
                                className={`form-control ${touched.fechaNacimiento && errors.fechaNacimiento ? 'is-invalid' : touched.fechaNacimiento && !errors.fechaNacimiento ? 'is-valid' : ''
                                    }`}
                            />
                            <ErrorMessage
                                name="fechaNacimiento"
                                component="div"
                                className="text-danger"
                            />
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col>
                            <label htmlFor='lugar_nacimiento' className='form-label'>Lugar de nacimiento</label>
                                <Field
                                    type="text"
                                    placeholder="San Antonio de Areco, Buenos Aires Argentina"
                                    id="lugar_nacimiento"
                                    name='lugar_nacimiento'
                                    className={`form-control ${touched.lugar_nacimiento && errors.lugar_nacimiento ? 'is-invalid' : touched.lugar_nacimiento && !errors.lugar_nacimiento ? 'is-valid' : ''
                                    }`}
                                />
                                <ErrorMessage
                                    name="lugar_nacimiento"
                                    component="div"
                                    className="text-danger"
                                />
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col md={6}>
                                <label htmlFor='telefono' className='form-label'>Teléfono</label>
                                <Field
                                    type="number"
                                    placeholder="Ingrese el telefono"
                                    id="telefono"
                                    name='telefono'
                                    className={`form-control ${touched.telefono && errors.telefono ? 'is-invalid' : touched.telefono && !errors.telefono ? 'is-valid' : ''
                                        }`}
                                />
                                <ErrorMessage
                                    name="telefono"
                                    component="div"
                                    className="text-danger"
                                />
                            </Col>
                            <Col md={6}>
                            <label htmlFor='email' className='form-label'>Email{asterico}</label>
                            <Field
                                type="text"
                                placeholder="Ingrese el email"
                                id="email"
                                name='email'
                                className={`form-control ${touched.email && errors.email ? 'is-invalid' : touched.email && !errors.email ? 'is-valid' : ''
                                    }`}
                            />
                            <ErrorMessage
                                name="email"
                                component="div"
                                className="text-danger"
                            />
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col md={4}>
                            <label htmlFor='provincia' className='form-label'>Provincia{asterico}</label>
                            <Field
                                component={CustomSelect}
                                onProvinceChange={handleProvinceChange}
                                id="provincia"
                                name='provincia'
                                className={`form-control ${touched.provincia && errors.provincia ? 'is-invalid' : touched.provincia && !errors.provincia ? 'is-valid' : ''
                                    }`}
                            />
                            <ErrorMessage
                                name="provincia"
                                component="div"
                                className="text-danger"
                            />
                            </Col>
                            <Col md={4}>
                            <label htmlFor='municipio' className='form-label'>Municipio{asterico}</label>
                            <Field
                                component={CustomSelectMunicipio}
                                provincia={provincias}
                                onMunicipioChange={handleMunicipioChange}
                                id="municipio"
                                name='municipio'
                                className={`form-control ${touched.municipio && errors.municipio ? 'is-invalid' : touched.municipio && !errors.municipio ? 'is-valid' : ''
                                    }`}
                            />
                            <ErrorMessage
                                name="municipio"
                                component="div"
                                className="text-danger"
                            />
                            </Col>
                            <Col md={4}>
                            <label htmlFor='localidad' className='form-label'>Localidad{asterico}</label>
                            <Field
                                component={CustomSelectLocalidad}
                                options={localidad}
                                id="localidad"
                                name='localidad'
                                className={`form-control ${touched.localidad && errors.localidad ? 'is-invalid' : touched.localidad && !errors.localidad ? 'is-valid' : ''
                                    }`}
                            />
                            <ErrorMessage
                                name="localidad"
                                component="div"
                                className="text-danger"
                            />
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col md={8}>
                            <label htmlFor='calle' className='form-label'>Calle{asterico}</label>
                            <Field
                                type="text"
                                placeholder="Ingrese el calle"
                                id="calle"
                                name='calle'
                                className={`form-control ${touched.calle && errors.calle ? 'is-invalid' : touched.calle && !errors.calle ? 'is-valid' : ''
                                    }`}
                            />
                            <ErrorMessage
                                name="calle"
                                component="div"
                                className="text-danger"
                            />
                            </Col>
                            <Col md={4}>
                            <label htmlFor='numero' className='form-label'>Número</label>
                            <Field
                                type="text"
                                placeholder="Ingrese el número"
                                id="numero"
                                name='numero'
                                className={`form-control ${touched.numero && errors.numero ? 'is-invalid' : touched.numero && !errors.numero ? 'is-valid' : ''
                                    }`}
                            />
                            <ErrorMessage
                                name="numero"
                                component="div"
                                className="text-danger"
                            />
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col>
                            <label htmlFor='nacionalidad' className='form-label'>Nacionalidad{asterico}</label>
                            <Field
                                component={CustomSelectNacionalidad}
                                options={pais}
                                id="nacionalidad"
                                name='nacionalidad'
                                className={`form-control ${touched.nacionalidad && errors.nacionalidad ? 'is-invalid' : touched.nacionalidad && !errors.nacionalidad ? 'is-valid' : ''
                                    }`}
                            />
                            <ErrorMessage
                                name="nacionalidad"
                                component="div"
                                className="text-danger"
                            />
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col className='text-center'>
                                <Button  variant='success'  onClick={console.log("boton")} type='submit' disabled={loading}>{loading?"Cargando":"Enviar"}</Button>
                            </Col>
                        </Row>
                        
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
export default FormCrearAlumno;