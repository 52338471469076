import { useState } from "react";
import { Col, Container, Form, Row, Button, Spinner } from "react-bootstrap";
import cflService from "../service/cfl-service";
import Tabla_Alumnos from "../components/Tabla_Alumnos";
import Tabla_Cursos from "../components/Tabla_Cursos";
import Tabla_Acta_Examen from "../components/Tabla_Acta_Examen";
import { FaFilePdf } from "react-icons/fa6";
import Espera from '../components/Espera'
import TablaAlumnosNotas from "../components/TablaAlumnosNotas";


const Nota = () => {
    const [criterioBusqueda, setCriterioDeBusqueda] = useState("");
    const [alumno, setAlumno] = useState([]);
    const [curso, setCurso] = useState([]);
    const [filaCurso, setFilaCurso] = useState();
    const [tableIsVisible, setTableIsVisible] = useState(false);
    const [alumnosMatriculadosActivos, setAlumnosMatriculadosActivos] = useState([]);
    const [loadingAlumnosMatriculados, setLoadingAlumnosMatriculados] = useState(false)
    const [loadindBuscarAlumno, setLoadingBuscarAlumno] = useState(false)
    const [loadindBuscarCurso, setLoadingBuscarCurso] = useState(false)
    const [loadingFichaCurso, setLoadingFichaCurso] = useState(false);

    const handleSelect = (e) => {
        console.log(e.target.value)
        setCriterioDeBusqueda(e.target.value)
    }

    const onInput = (e) => {

        if (criterioBusqueda === "dni") {
            let porDni = e.target.value
            if (porDni.length >= 3) {
                setLoadingBuscarAlumno(true)
                cflService.buscarPorDni(e.target.value)
                    .then((resp) => {
                        console.log(resp.data.data)
                        setAlumno(resp.data.data)
                        setLoadingBuscarAlumno(false)
                    })
                    .catch((error) => console.log(error))
            }

        }
        if (criterioBusqueda === "Nombre") {
            let nombre = e.target.value
            if (nombre.length >= 3) {
                setLoadingBuscarAlumno(true)
                cflService.buscarPorApellido(e.target.value)
                    .then((resp) => {
                        console.log(resp.data.data)
                        setAlumno(resp.data.data)
                        setLoadingBuscarAlumno(false)
                    })
                    .catch((error) => console.log(error))
            }

        }
    }
    const onInputCurso = (e) => {
        setLoadingBuscarCurso(true)
        if (criterioBusqueda === "nro_curso") {
            cflService.buscarCursoPorNroCurso(e.target.value)
                .then((resp) => {
                    console.log(resp.data.data)
                    setCurso(resp.data.data)
                    setLoadingBuscarCurso(false)
                })
                .catch((error) => console.log(error))
        }
        if (criterioBusqueda === "Nombre") {
            cflService.buscarCursoPorNombre(e.target.value)
                .then((resp) => {
                    console.log('Buscar curso por nombre', resp.data.data)
                    setCurso(resp.data.data)
                    setLoadingBuscarCurso(false)
                })
                .catch((error) => console.log(error))
        }
    }

    const printNotas = async () => {
        setLoadingFichaCurso(true)

        alumnosMatriculadosActivos.map((data) => {
            data.alumnosHasCursosByIdAlumnos.map(async (nota) => {
                console.log("NOTA", nota);
                await cflService.updateNotas(nota)
                    .then((res) => {
                        console.log("esta joya");
                    })
                    .catch((error) => {
                        console.log("salio mal");
                    });
            });

        })
        await cflService.actadeexamenPdf(filaCurso?.idCurso)
        setLoadingFichaCurso(false)
    }

    return (
        <Container className="mb-3 mt-3">
            {!tableIsVisible &&
                <>
                <Row className="bg-dark  rounded-top-4 align-items-end pt-2 mt-3">
                    <h2 className="text-white" style={{fontVariant:'all-small-caps'}}>Buscar Cursos</h2>
                </Row>
                <Row className=" align-middle border border-dark pt-4 pb-4 ps-2">
                    <Col xs={12} md={2} className="mt-2 text-center">
                        <Form.Check
                            inline
                            label="Nombre"
                            name="radio-busqueda1"
                            value="Nombre"
                            type="radio"
                            onClick={handleSelect} />
                    </Col>
                    <Col xs={12} md={2} className="mt-2 text-center">
                        <Form.Check
                            inline
                            label="N° Curso"
                            name="radio-busqueda1"
                            value="nro_curso"
                            type="radio"
                            onClick={handleSelect} />
                    </Col>
                    <Col xs={12} md={8} >
                        <Form.Group className="mb-6" controlId="buscar">
                            <Form.Control type="text" placeholder={`Ingresa ${criterioBusqueda}`} onChange={onInputCurso} />
                        </Form.Group>
                    </Col>
                </Row>
                    
                    {loadindBuscarCurso && <Espera />}
                    {!loadindBuscarCurso && <Tabla_Acta_Examen
                        curso={curso}
                        setFilaCurso={setFilaCurso}
                        setVisible={setTableIsVisible}
                        setAlumnosMatriculadosActivos={setAlumnosMatriculadosActivos}
                        setLoadingAlumnosMatriculados={setLoadingAlumnosMatriculados}
                    />}
                </>}
            {tableIsVisible &&
                <Container>
                    <Row className="bg-dark  rounded-top-4 align-items-end pt-2 mt-3">
                        <h4 className="text-white" style={{fontVariant:'all-small-caps'}}>Elegiste el siguiente curso</h4>
                    </Row>
                    <Row className=" align-middle border border-dark">
                        <h4 className="text-dark text-end align-middle" style={{fontWeight:'bold'}}>{filaCurso.nombre}</h4>
                    </Row>
                    {loadingAlumnosMatriculados && <Espera />}
                    {!loadingAlumnosMatriculados &&
                        <TablaAlumnosNotas
                            alumnoxCurso={alumnosMatriculadosActivos}
                            setAlumnosxCurso={setAlumnosMatriculadosActivos}
                            filacurso={filaCurso}
                        />}
                    
                    <Col xs={12} className="d-flex" >
                        <Button variant="success" className="ms-auto me-5 mt-3" onClick={() => printNotas()} >
                            {loadingFichaCurso ? (
                                <Spinner animation="border" />
                                ) : (
                                <>
                                Generar acta de examen <FaFilePdf />
                                </>
                            )}
                        </Button>
                    </Col>
                </Container>
            }
        </Container>
    )
}
export default Nota;