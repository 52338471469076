import React from 'react';
import Select from 'react-select';

const CustomSelect = ({ field, form, options, ...props }) => {
  console.log(field)
  const handleChange = (selectedOption) => {
    form.setFieldValue(field.name, selectedOption);
  };

  const selectedOption = options.find(
    (option) => option.label === field.value?.label
  );
   
  return (
    <Select
      {...props}
      options={options}
      value={selectedOption}
      onChange={handleChange}
    />
  );
};

export default CustomSelect;
