import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import  cflService from '../service/cfl-service';
import { FiPrinter } from "react-icons/fi";

const ModalImpresionAsistencia = ({ show, handleClose, curso }) => {
  const [mesSeleccionado, setMesSeleccionado] = useState("")
  const [meses, setMeses] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (show) {
      setMesSeleccionado("");
    }
  }, [show]);

  useEffect(() => {
    setLoading(true);
    if (curso) {
      const fetchMeses = async () => {
        try {
          const response = await cflService.listaMesesCurso(curso.idCurso);
          if (response.data.success) {
            setMeses(response.data.data);
            console.log('Meses',response.data.data)
          } else {
            console.error("Error en la respuesta de la API:", response);
            alert("No se pudieron cargar los meses disponibles.");
          }
        } catch (error) {
          console.error("Error al obtener los meses:", error);
          alert("Hubo un error al cargar los meses.");
        } finally {
          setLoading(false); // Finaliza el estado de carga
        }
      };
      fetchMeses();
    }
  }, [curso]);

  const handleElegirMes = (event) => {
    setMesSeleccionado(event.target.value);
  }

  const handleConfirmar = async() => {
    setLoading(true);
    try {
      await cflService.planillaDeAsistencia (curso.idCurso, mesSeleccionado)
    } catch (error) {
      console.error("Error al generar el PDF:", error);
      alert("Hubo un error al generar el PDF.");
      }  finally {
        setLoading(false); 
      }
  }
  

  return (
    <Modal show={show} onHide={!loading ? handleClose : undefined} backdrop={loading ? "static" : true}>
      <Modal.Header closeButton className="bg-dark text-white">
        <Modal.Title> <FiPrinter /> Imprimir Asistencia</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
            <Spinner animation="border" variant="primary" />
            <span className="ms-2">Generando PDF, por favor espere...</span>
          </div>
        ) : (
          <>
        <p>Seleccione el mes para imprimir el informe de asistencias del curso <strong>{curso?.nombre}</strong>.</p>
        <Form.Select aria-label="Selector de mes de asistencia" 
          value={mesSeleccionado} 
          onChange={handleElegirMes}
          disabled={loading}
        >
          <option value="" disabled>Seleccionar mes</option>
          {meses.map((mes, index) => (
            <option key={index} value={mes.mes}>
              {mes.nombre.charAt(0).toUpperCase() + mes.nombre.slice(1)}
            </option>
          ))}
        </Form.Select>
        </>
        )}
      </Modal.Body>
      <Modal.Footer className="bg-dark">
        <Button variant="secondary" onClick={handleClose} disabled={loading}>
          Cerrar
        </Button>
        <Button variant="success" onClick={handleConfirmar} disabled={!mesSeleccionado || loading}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalImpresionAsistencia;
