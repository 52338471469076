import React from "react"
import FormCursoDos from "../components/FormCursoDos"
import { Container, Row, Col } from "react-bootstrap"


const initialValues = {
    nombre: "",
    fechaInicio: "",
    fechaFinalización: "",
    cupo: ""
}

const CrearCurso = () => {
    return (
        <Container fluid className='' style={{ width: '100% !important' }}>
        <Container fluid>
        <Row className="bg-dark  rounded-top-4 align-items-end pt-2 mt-3">
            <Col md={6}>
                <h2 className="text-white" style={{fontVariant:'all-small-caps'}}>Formulario</h2>
            </Col>
            <Col md={6}>
                <h4 className="text-white text-end align-middle" style={{fontWeight:'bold'}}>Nuevo Curso</h4>
            </Col>
        </Row>
        <Row className=" align-middle border border-dark">
            <h5 className="text-dark text-center align-middle" style={{fontWeight:'bold', fontVariant: 'all-petite-caps'}}>Ingresar datos</h5>
        </Row>
        </Container>
        <FormCursoDos initialValues={initialValues}/>
        </Container>
    )
}

export default CrearCurso