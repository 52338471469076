import { useEffect } from "react";
import { Table, Button} from "react-bootstrap";
import cflService from "../service/cfl-service";
import { IoPersonAddOutline } from 'react-icons/io5';


const Tabla_Alumnos = ({ alumno, alumnosxCurso, filaCurso, setAlumnosxCurso }) => {


    let mostrar = alumno.filter((elemento) => {
        return !alumnosxCurso.some(objetoSubconjunto => objetoSubconjunto.idAlumnos === elemento.idAlumnos)
    })
    console.log("mostrar", mostrar)

    const handleCheck = (data) => {
        console.log(data)
        cflService.matricularAlumno(data.idAlumnos, filaCurso.idCurso)
            .then((resp) => console.log(resp))
            .catch((e) => console.log(e))
        setAlumnosxCurso([...alumnosxCurso, data])



    }

    return (
        <Table striped bordered hover className="mt-3" responsive>
            <thead>
                <tr>
                    <th>DNI</th>
                    <th>Nombres</th>
                    <th>Apellidos</th>
                    <th>Email</th>
                    <th>Acción</th>
                </tr>
            </thead>
            <tbody>

                {mostrar.map((data, i) => {
                    return (
                        <tr key={i}>
                            <td>{data.dni}</td>
                            <td>{data.nombre}</td>
                            <td>{data.apellido}</td>
                            <td>{data.email}</td>
                            <td>
                                <div className="center-accion">
                                    <Button
                                        variant="outline-primary"
                                        onClick={() => handleCheck(data)}
                                    >
                                        <IoPersonAddOutline />
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}

export default Tabla_Alumnos;