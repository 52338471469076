import http from "./http-common";
import authHeader from './auth-header';
import axios from "axios";

const enviarConfirmacion = (mail, nombre) => {
    return http.get("/api/auth/mail?email=" + mail + "&nombre=" + nombre)
}

const nuevopassword = (email, password) => {
    const data = {
        email,
        password
    }
    return http.post("/api/auth/nuevopassword", data)
}

const listaMesesCurso = (id) => {
  return http.get(`/api/curso/getmesescurso/${id}`, { headers:authHeader()})
}

const actualizarAsistencia=(idAsistencia, data)=>{
  return http.put(`/api/asistencia/updateasistencia/${idAsistencia}`, data, { headers:authHeader()})
}

const traerAsistenciaHistorica=(idCurso)=> {
  return http.get(`/api/cohorte/getcohortehistorico/${idCurso}`, { headers:authHeader()})
}

const enviarEstadoAlumno=(data)=>{
  return http.put("/api/alumnocurso/updatesqldelete", data, { headers:authHeader()})
}

const obtenerUltimosCincoCohortes=()=>{
  return http.get("/api/curso/buscarultimoscincocohortes",  { headers:authHeader() })
}

const updateShowCohorte=(idCohorte)=>{
  return http.post("/api/curso/updateshowcohorte/"+idCohorte,{},{ headers:authHeader() })
}
const crearAsistencia=(data)=>{
  return http.post("/api/asistencia/crearasistencia", data, { headers:authHeader() })
}

//alumnos x curso para asistencia de alumnos
const getalumnosxcurso=(idCurso)=> {
  return http.get(`/api/alumno/alumnosxxcurso/${idCurso}`,{headers:authHeader()})
}

//cohortes x curso
const getcohortexcurso=(idCurso)=>{
  return http.get(`/api/curso/${idCurso}/cohortes`,{ headers: authHeader() })
}
//ver alumnos por cohorte 
const getalumnosxcohorte=(idCohorte)=>{
  return http.get(`/api/alumno/alumnosxcohorte/${idCohorte}`, { headers: authHeader() })
}
const enviarConfirmacionPass = (mail) => {
    return http.get("/api/auth/mailpassword?email=" + mail)
}


const buscarPorDni=(dni)=>{
    
   
    return http.get("/api/alumno/getbydni/"+dni,{ headers: authHeader() })
}

const buscarPorApellido=(apellido)=>{
    return http.get("api/alumno/getbyapellido/"+apellido,{headers: authHeader()})
}

const buscarCursoPorNombre=(nombre)=>{
    return http.get("api/curso/getbynombre/"+nombre,{headers: authHeader()})
}

const buscarCursoPorNroCurso=(numero)=>{
    return http.get("api/curso/getbynro_curso/"+numero,{headers: authHeader()})
}

const buscarAlumnosMatriculadosCurso=(id_curso)=>{
    return http.get("api/alumno/getalumnosbycurso/"+id_curso,{headers: authHeader()})
}

// Alumnos activos (Acta de Examen)
const getAlumnosActivosPorCurso=(id_curso)=>{
  return http.get("api/alumno/getalumnosactivos/"+id_curso,{headers: authHeader()})
}

const matricularAlumno=(id_alumno,id_curso)=>{
    const data={
        alumnosIdAlumnos:id_alumno,
        cursoIdCurso:id_curso
      }
      return http.post("/api/alumno/matricular/",data,{headers: authHeader()})
}

const deleteMatricular=(id_curso,id_alumno)=>{
    return http.delete(`/api/alumno/delete/matricula/${id_curso}/${id_alumno}`,{headers: authHeader()})
}
const deleteAlumno=(id)=>{
    return http.delete(`/api/alumno/borrar/${id}`,{headers: authHeader()})
}

const deleteCurso=(id) => {
    return http.delete(`/api/curso/borrar/${id}`,{headers: authHeader()})
}

const crearAlumno=(data)=>{
    
    return http.post('/api/alumno/crear',data,{headers: authHeader()})
}

const crearCurso =(data) => {
  return http.post('/api/curso/crear',data,{headers: authHeader()})
}

const updateAlumno=(data)=>{
    
    return http.put('/api/alumno/update',data,{headers: authHeader()})
}

const buscarTodosAlumnosPaginado=(page,size)=>{
    return http.get(`/api/alumno/getallpaging?page=${page}&size=${size}`,{headers: authHeader()})
}

const buscarTodosAlumnos=()=>{
    return http.get(`/api/alumno/getAll`,{headers: authHeader()})
}

const buscarTodosLosCursos=()=>{
  return http.get(`api/curso/getAll`,{headers: authHeader()})
}

const updateAsistencia=(id_asistencia, data)=> {
  return http.put(`/api/curso/updateasistencia/${id_asistencia}`, data, {headers: authHeader()})
}

//PDFs
const planillaDeAsistencia=async (idCurso, fechaInicio)=>{
  try {
    const response = await http.get(`/api/asistenciapdf/${idCurso}/${fechaInicio}`, {
      headers: authHeader(),
      responseType: 'blob'
    });
    //Create a Blob from the PDF Stream
    const file = new Blob(
      [response.data],
      { type: 'application/pdf' });
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);
    //Open the URL on new Window
    window.open(fileURL);
  } catch (error) {
    console.error("Error al generar la planilla de asistencia:", error);
    alert("Hubo un error al generar la planilla de asistencia. Por favor, inténtelo nuevamente.");
  }
}


const portadapdf=async (id)=>{
    try {
      const response = await http.get(`/api/portada/${id}`, {
        headers: authHeader(),
        responseType: 'blob'
      });
      //Create a Blob from the PDF Stream
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      window.open(fileURL);
    } catch (error) {
      //console.log(error);
    }
  }
  
  const actadeexamenPdf=async (id)=>{
    try {
      const response = await http.get(`/api/actadeexamen/${id}`, {
        headers: authHeader(),
        responseType: 'blob'
      });
      //Create a Blob from the PDF Stream
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      window.open(fileURL);
    } catch (error) {
      //console.log(error);
    }
  }

  const updateNotas=async(data) =>{
    return await http.put("/api/alumnocurso/update",data,{headers: authHeader()})
  }

const cflService={
    enviarConfirmacion,
    buscarPorDni,
    buscarPorApellido,
    buscarCursoPorNombre,
    buscarCursoPorNroCurso,
    buscarAlumnosMatriculadosCurso,
    matricularAlumno,
    deleteMatricular,
    portadapdf,
    crearAlumno,
    buscarTodosAlumnosPaginado,
    buscarTodosAlumnos,
    updateAlumno,
    deleteAlumno,
    updateNotas,
    actadeexamenPdf,
    buscarTodosLosCursos,
    deleteCurso,
    crearCurso,
    getalumnosxcohorte,
    getcohortexcurso,
    updateAsistencia,
    getalumnosxcurso,
    crearAsistencia,
    obtenerUltimosCincoCohortes,
    updateShowCohorte,
    enviarEstadoAlumno,
    traerAsistenciaHistorica,
    actualizarAsistencia,
    planillaDeAsistencia,
    listaMesesCurso,
    getAlumnosActivosPorCurso
}



export default cflService