import axios from "axios";

const API_URL ="https://api.administracion.cfl401areco.edu.ar/api/auth/" //"http://localhost:8080/api/auth///

class AuthService {
    async login(username, password) {
        console.log(`username : ${username} pass : ${password}`)
        const response = await axios
            .post(API_URL + "signin", {
                username,
                password
            });
        if (response.data.accessToken) {
            //console.log(response.data.accessToken);
            localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
    }

    logout() {
        localStorage.removeItem("user");
    }

    register(username, email, password) {
        return axios.post(API_URL + "signup", {
            username,
            email,
            password
        });
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('user'));;
    }
}

export default new AuthService();