import Swal from 'sweetalert2';

const AlertaExito = ({ title }) => {
Swal.fire({
    position: 'center',
    icon: 'success',
    title: title || 'La información se guardó correctamente',
    showConfirmButton: false,
    timer: 2000,
  });
};

export default AlertaExito;