import { Card, Row, Col, Container, Form, ListGroupItem, ListGroup, Button, Spinner, ButtonGroup, ToggleButton, Modal } from "react-bootstrap";
import React, { useEffect, useState } from 'react';
import avatar from '../assest/avatar.png';
import { useLocation, useNavigate } from "react-router-dom";
import cflService from "../service/cfl-service";
import moment from "moment/moment";
import { FaCheck, FaTimes, FaUserCheck, FaUserSlash, FaExclamationTriangle } from 'react-icons/fa';
import 'moment/locale/es';
import '../pages/AsistenciaPlanilla.css'
import { AiFillPrinter } from "react-icons/ai";
import AlertaError from '../components/AlertaError';
import AlertaExito from "../components/AlertaExito";
import ModalImpresionAsistencia from "../components/ModalImpresionAsistencia";
import { locale } from "moment/moment";

moment.locale('es'); 
const AsistenciaPlanilla=()=>{
    const [cohorte,setCohorte] = useState([]);
    const [cohorteSeleccionado, setCohorteSeleccionado] = useState('');
    const [hayCohorteSeleccionada, setHayCohorteSeleccionada] = useState(false);
    const location = useLocation();
    const [alumnos, setAlumnos] = useState([]);
    const [totalAlumnos, setTotalAlumnos] = useState(0);
    const [loading, setLoading] = useState(false);
    const { idCurso } = location.state || {};
    const [botonesEstado, setBotonesEstado] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [mostrarLibres, setMostrarLibres] = useState(false);
    const [loadingAsistencia, setLoadingAsistencia] = useState(false);
    const [showImpresionAsistencia, setShowImpresionAsistencia] = useState(false);
    const navigate = useNavigate();

    //CLICK PARA HISTORIAL DE ASISTENCIA
    const handleClick = () => {
        navigate("/asistenciahistorial", {state:{ ...location}});
      };
    
    //LISTA DE COHORTES, organizadas por fecha
    useEffect(()=>{
        let curso=location.state;
        console.log('curso: ',curso)
        cflService.obtenerUltimosCincoCohortes()
        .then((resp)=>{
            console.log("Fechas por curso",resp.data)
            if (resp.data.success){
                let cohortexfecha=resp.data?.data
                setCohorte(cohortexfecha)
            }
        })
        .catch((e)=>{
            console.log(e)
        })
    },[location.state]);


    // ID COHORTE para ver alumnos al cambiar cohorte
    const handleCambioCohorte = (e) => {
        const idCohorte = e.target.value;

        setCohorteSeleccionado(parseInt(idCohorte));
        setHayCohorteSeleccionada(true); 
        setLoading(true); //cargando alumnos
        setMostrarLibres(false);

        cflService.getalumnosxcurso(idCurso)
        .then((resp) => {
            console.log("alumnos por Curso", resp.data);
            if (resp.data.success) {
                // Alumnos ordenados alfabéticamente por apellido
                const alumnosOrdenados = resp.data.data.sort((a, b) => {
                    const apellidoA = a.apellido.toLowerCase();
                    const apellidoB = b.apellido.toLowerCase();
                    if (apellidoA < apellidoB) return -1;
                    if (apellidoA > apellidoB) return 1;
                    return 0; // Son iguales
                });
                setAlumnos(alumnosOrdenados); // Recibirá los cambios
                setTotalAlumnos(alumnosOrdenados.length);
                setLoading(false); //terminó la carga de alumnos
                console.log('alumnos:', alumnosOrdenados);

                console.log(`Cantidad de alumnos en la cohorte ${idCohorte}: ${alumnosOrdenados.length}`);

                // Se inicializa el estado de los botones para el nuevo cohorte
                const estadoInicialBotones = {};
                alumnosOrdenados.forEach((alumno) => {
                    estadoInicialBotones[alumno.id] = {
                        leftButtonPressed: alumno.borrado, 
                        rightButtonPressed: !alumno.borrado
                    };
                });
                setBotonesEstado(estadoInicialBotones);

                }
            })
            
        .catch ((error) => {
            console.log(error, "Error: handleCambioCohorte");
            setLoading(false); //termina la carga en caso de error
        })
    };
    

    //GUARDAR EL ESTADO DE CADA BUTTON DEL CARD.
    const handleButtonClick = (idAlumno, tipo) => {
        setBotonesEstado((prevState) => {
            const nuevoEstado = {
                ...prevState,
            [idAlumno]: {
                ...prevState[idAlumno],
                [tipo]: !prevState[idAlumno]?.[tipo],
            },
    }
            // Obtener el estado del botón actualizado
        const estadoPresente = nuevoEstado[idAlumno].rightButtonPressed; 
        console.log(`ID: ${idAlumno}, Presente: ${estadoPresente}`);


        const estadoAlumno = nuevoEstado[idAlumno].leftButtonPressed;
        console.log(`ID: ${idAlumno}, Estado: ${estadoAlumno}`)
        
        return nuevoEstado;
    });
};

    //CALCULAR PRESENTES / AUSENTES
    const calcularEstadisticas = () => {
        const alumnoRegular = alumnos.filter(alumno => !alumno.borrado);
        const presentes = alumnoRegular.filter(alumno => botonesEstado[alumno.id]?.rightButtonPressed).length;
        const ausentes = alumnoRegular.filter(alumno => !botonesEstado[alumno.id]?.rightButtonPressed).length;
        const totales = presentes + ausentes;

        return { presentes, ausentes, totales };
    };

    //Acá llamamos a la funcion para calcular las estadísticas
    const { presentes, ausentes, totales } = calcularEstadisticas();


    // Modal handlers
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    
    // Función para abrir el modal
    const mostrarConfirmacionAsistencia = () => {
        handleShow(); // Mostrar el modal
    };

    // ENVIAMOS ASISTENCIA Y ESTADO
    const enviarDatos = () => {

    setLoadingAsistencia(true);

    const resultados = Object.entries(botonesEstado).map(([idAlumno, estados]) => {
        const estadoPresente = estados.rightButtonPressed;
        const estadoAlumno = estados.leftButtonPressed;

        return enviarAsistenciaYEstado(idAlumno, estadoPresente, estadoAlumno);
    });

    // PROMESA, CUANDO TODOS LOS ENVÍOS TERMINAN:
    Promise.all(resultados)
        .then(() => {
            AlertaExito({ message: 'Datos enviados con éxito para todos los alumnos.' });

            // Actualizar el estado del cohorte y cerrar
            cflService.updateShowCohorte(cohorteSeleccionado)
                .then((response) => {
                    console.log('Cohorte actualizado:', response);
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                })
                .catch((error) => {
                    console.error('Error al actualizar el cohorte:', error);
                })
                .finally(() => {
                    setLoadingAsistencia(false); 
                });

            handleClose();
        })
        .catch((error) => {
            console.error('Error en el proceso de envío de datos:', error);
            AlertaError({ message: 'Hubo un problema al enviar los datos. Por favor, intente nuevamente.' });
        });
    };

        // PREPARAR ENVÍO DE DATOS (ASISTENCIA Y ESTADO DEL ALUMNO)
        const enviarAsistenciaYEstado = (idAlumno, estadoPresente, estadoAlumno) => {
            // Enviar estado siempre
            const dataEstado = {
                idCurso: idCurso,
                idAlumno: idAlumno,
                sqlDelete: estadoAlumno ? 1 : 0
            };

            // ENVÍO DE ASISTENCIA CON CONDICIONAL DE 'SQLDELETE'EN FALSE
            const promesas = [cflService.enviarEstadoAlumno(dataEstado)];
            if (!estadoAlumno) {
                const dataAsistencia = {
                    idCohorte: cohorteSeleccionado,
                    idAlumno: idAlumno,
                    presente: estadoPresente
                };
                promesas.push(cflService.crearAsistencia(dataAsistencia));
            }

            return Promise.all(promesas);
        };


        // MOSTRAR LIBRES
        const handleCheckBoxMostrarLibres = () => {
        setMostrarLibres(!mostrarLibres); // Alternar el estado del checkbox
    };

    //MODAL IMPRESION
    const handleShowImpresionAsistencia = () => setShowImpresionAsistencia(true);
    const handleCloseImpresionAsistencia = () => setShowImpresionAsistencia(false);

    return(
        <Container className="mt-5 mb-4" md={12}>
            <Container fluid>
                <Row className="bg-dark border-bottom rounded-top-4 align-items-end pt-2">
                    <Col xs={12} sm={7}  md={8} lg={9}>
                        <h2 className="text-white" style={{fontVariant:'all-small-caps'}}>{location.state?.nombre || ''}</h2>
                    </Col>
                    <Col xs={12} sm={5} md={4} lg={3} className="text-end pe-3">
                        <h1 className="text-light" style={{fontWeight:'bold'}}>Asistencia</h1> 
                    </Col>
                </Row>
            </Container>
            <Container className="border-bottom border-dark">
                <Row className='d-flex justify-content-center bg-light'>
                    <Col sm={12} md={12} lg={6} className="mt-1 mb-1">
                        <Form.Select aria-label="Selección de fecha de cursada" defaultValue={'seleccionFecha'}
                            onChange={handleCambioCohorte}>
                            <option disabled value='seleccionFecha'>Seleccioná la fecha de cursada</option>
                            {cohorte.map((cohort, index) => (
                                <option key={index} value={cohort.idCohorte}>
                                    {moment(cohort.fecha).format("dddd, DD [de] MMMM [de] YYYY")} 
                                </option>
                            ))}
                        </Form.Select>
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                        <Form className="mt-2">
                                <Form.Check
                                    type="checkbox"
                                    id="mostrarLibres"
                                    checked={mostrarLibres}
                                    onChange={handleCheckBoxMostrarLibres}
                                    label= "incluir alumnos libres"
                                    style={{ fontVariant: 'small-caps' }}
                                    className="fw-semibold"
                                />
                        </Form>
                    </Col>
                    <Col sm={12} md={6} lg={3} className="text-end">
                        <Button size="sm" variant="outline-dark" className="mt-2 me-0 mb-1" onClick={handleClick} >Historial de Asistencia</Button>
                    </Col>
                </Row>
            </Container>
            <Row className="mt-2">
                <Col className="text-end">
                    <Button variant="dark" onClick={handleShowImpresionAsistencia}><AiFillPrinter /> Imprimir Asistencia</Button>
                </Col>
            </Row>
            <Container className="mt-4 ">
                <Row className="border-bottom border-dark border-2 justify-content-end">
                    <Col md={4}>
                        <h2 className=' text-dark' style={{fontVariant:'small-caps', fontWeight:'600'}}>Alumnos</h2>
                    </Col>
                    <Col md={8}>
                        <ListGroup horizontal className='d-flex justify-content-end border-0 custom-list-group'>
                            <ListGroupItem className='  fw-semibold custom-list-group-item  ' style={{ fontVariant: 'small-caps', color:'hsl(141deg, 40%, 50%)' }}>Presentes: {presentes}</ListGroupItem>
                            <ListGroupItem className='text-danger fw-semibold custom-list-group-item' style={{ fontVariant: 'small-caps' }}>Ausentes: {ausentes}</ListGroupItem>
                            <ListGroupItem className='text-black custom-list-group-item fw-bold' style={{ fontVariant: 'small-caps' }}>Total: {totales}</ListGroupItem>
                            <ListGroupItem className='text-dark fw-semibold custom-list-group-item  border-start border-3' style={{ fontVariant: 'small-caps' }}>Inscriptos: {totalAlumnos}</ListGroupItem>
                        </ListGroup>
                    </Col>
                </Row>
                <Row className='pb-2'>
                    {loading ? (
                        <div className="text-center my-5">
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Cargando...</span>
                            </Spinner>
                            <div>
                                <span style={{fontWeight:'bold', textTransform:'uppercase'}} >Cargando lista de asistencia</span>
                            </div>
                        </div>
                    ) : (
                    <ListGroup className="mt-4 ">
                        <Row className='g-3 ms-1 justify-content-center'>
                        {alumnos
                            .filter(alumno => !alumno.borrado || mostrarLibres) // Filtrar alumnos: mostrar solo inhabilitados si el checkbox está activado
                            .map((alumno) => { 
                                const estadoAlumno = botonesEstado[alumno.id] || {}; // Acá obtengo el estado de los botones del alumno
                                const leftButtonPressed = estadoAlumno.leftButtonPressed !== undefined ? estadoAlumno.leftButtonPressed : alumno.borrado;
                                const rightButtonPressed = estadoAlumno.rightButtonPressed || false;
                                
                                const isDisabled = (mostrarLibres && alumno.borrado) || false;
                                return (
                            <Col 
                            key={alumno.id}
                            xxl={3}
                            xl={4}  
                            lg={4} 
                            md={6} 
                            sm={12} 
                            xs={12} 
                            >
                                <Card className="custom-card">
                                    <Row className="g-0">
                                    <Col xs={4} className="d-flex align-items-stretch">
                                        <Card.Img
                                        src={avatar}
                                        alt="Imagen"
                                        className="custom-card-img"
                                        />
                                    </Col>
                                    <Col>
                                        <Card.Body className="d-flex flex-column justify-content-between p-0 m-0">
                                        <Row>
                                            <Col className="d-flex justify-content-center">
                                            <Card.Title className="custom-card-title text-center pe-2">
                                                <span className="fw-bold fs-6 fs-md-5">{alumno.apellido}</span>, <span className="fs-6 fs-md-5">{alumno.nombre}</span>
                                            </Card.Title>
                                            </Col>
                                        </Row>
                                        </Card.Body>
                                    </Col>
                                    </Row>

                                    <Row className="border-0 m-0 g-0 p-0 rounded-bottom">
                                    
                                        <ButtonGroup className="border-0 custom-buttongroup">
                                            <ToggleButton
                                                type="checkbox"
                                                className={`custom-left-button ${leftButtonPressed ? 'active' : ''}`}
                                                checked={leftButtonPressed}
                                                disabled={isDisabled}
                                                onClick={() => handleButtonClick(alumno.id, 'leftButtonPressed')}
                                            >
                                                {leftButtonPressed ? <FaUserSlash className="me-2" /> : <FaUserCheck className="me-2" />}
                                                {leftButtonPressed ? "ALumno Libre" : "Dar de Baja"}
                                            </ToggleButton>

                                            <ToggleButton
                                                type="checkbox"
                                                className={`custom-right-button ${rightButtonPressed ? 'active' : ''}`}
                                                checked={rightButtonPressed}
                                                disabled={isDisabled}
                                                onClick={() => handleButtonClick(alumno.id, 'rightButtonPressed')}
                                            >
                                                {rightButtonPressed ? <FaCheck className="me-2" /> : <FaTimes className="me-2" />}
                                                {rightButtonPressed ? "presente" : "ausente"}
                                            </ToggleButton>
                                        </ButtonGroup>
                                    </Row>
                                </Card>
                            </Col>
                            );
                        })} 
                        </Row>
                    </ListGroup>
                    )}
                </Row>

            {/*Modal para Imprimir Asistencia*/}
            <ModalImpresionAsistencia 
                show={showImpresionAsistencia} 
                handleClose={handleCloseImpresionAsistencia} 
                curso={location.state}
            />
                
            {/* Modal de Confirmación */}
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton className="bg-dark text-white">
                    <Modal.Title>
                        <FaExclamationTriangle className="text-warning me-2" /> Confirmar Asistencia
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-light">
                    <p>¿Está seguro/a de que desea confirmar los datos de asistencia para el curso <strong>{location.state?.nombre}</strong>, en la cohorte del <strong>{cohorteSeleccionado && cohorte.length > 0 ? (
                (() => { const cohorteSeleccionadoData = cohorte.find(c => c.idCohorte === cohorteSeleccionado);
                    return cohorteSeleccionadoData ?  moment(cohorteSeleccionadoData.fecha).format("dddd, DD [de] MMMM [de] YYYY")
                        : "Cohorte no encontrada";})()
                    ) : ( "Cohorte no disponible" )}</strong>?</p>
                    <p className="text-center text-success"><strong>Presentes: {presentes} </strong></p>
                    <p className="text-center text-danger"><strong>Ausentes: {ausentes} </strong></p>
                </Modal.Body>
                <Modal.Footer className="bg-dark">
                    <Button variant="danger" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button variant="light" onClick={enviarDatos} disabled={loadingAsistencia}>
                    {loadingAsistencia ? "Enviando..." : "Confirmar Asistencia"}
                    </Button>
                </Modal.Footer>
            </Modal>

                <Row className="d-flex align-items-center justify-content-center mt-3 ">
                {hayCohorteSeleccionada && alumnos.length > 0 && !loading && (
                    <Button onClick={mostrarConfirmacionAsistencia} className="mb-5" variant="dark">Confirmar Asistencia</Button>
                )}
                </Row>

            </Container>
            
    </Container>

        
    
    )
}

export default AsistenciaPlanilla;