import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import georef from '../service/goref-service';

const  CustomSelect = ({ field, form, onProvinceChange,valueProv,setValueProv, ...props}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await georef.getAllProvincias();
        const data = response.data.provincias;
        console.log(data)
        // Transforma los datos de tu API en el formato requerido por react-select
        const formattedOptions = data.map((province) => ({
          value: province.id,
          label: province.nombre,
        }));
        
        setOptions(formattedOptions);
      } catch (error) {
        console.error('Error al obtener las provincias', error);
      }
    };

    fetchOptions();
  }, []);

  const handleChange = (selectedOption) => {
    form.setFieldValue(field.name, selectedOption);
    onProvinceChange(selectedOption);
  };

  const selectedOption1 = options.find(
    (option) => option.label === field.value?.label
  );

  
  
  if (setValueProv){
    setValueProv(selectedOption1)
  }
  return (
    <Select
      {...props}
      options={options}
      value={selectedOption1} // Usa el valor del campo del formulario como valor seleccionado
      onChange={handleChange}
      
      
    />
  );
};

export default CustomSelect;