import React,{useEffect,useState} from 'react';
import { Row, Container, Button, Table, Form, Col, Dropdown, ButtonGroup, DropdownButton, Pagination, InputGroup } from 'react-bootstrap';
import { FaSearch } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import cflService from '../service/cfl-service';
import Espera from '../components/Espera';
import Tabla_Cursos from '../components/Tabla_Cursos';

const Asistencia=()=>{
    const [criterioBusqueda, setCriterioDeBusqueda] = useState("");
    const [alumno, setAlumno] = useState([]);
    const [curso, setCurso] = useState([]);
    const [filaCurso, setFilaCurso] = useState();
    const [tableIsVisible, setTableIsVisible] = useState(false);
    const [alumnosxCurso,setAlumnosxCurso]=useState([]);
    const [loadingAlumnosMatriculados, setLoadingAlumnosMatriculados]=useState(false)
    const [loadindBuscarAlumno,setLoadingBuscarAlumno]=useState(false)
    const [loadindBuscarCurso,setLoadingBuscarCurso]=useState(false)
    const [loadingFichaCurso,setLoadingFichaCurso]=useState(false);
    const navigate = useNavigate();
    //search
    const handleSelect = (e) => {
        console.log(e.target.value)
        setCriterioDeBusqueda(e.target.value)
    }
    //criterio de busqueda
    const onInputCurso = (e) => {
        setLoadingBuscarCurso(true)
        if (criterioBusqueda === "nro_curso") {
            cflService.buscarCursoPorNroCurso(e.target.value)
                .then((resp) => {
                    console.log(resp.data.data)
                    setCurso(resp.data.data)
                    setLoadingBuscarCurso(false)
                    
                })
                .catch((error) => console.log(error))
        }
        if (criterioBusqueda === "Nombre") {
            cflService.buscarCursoPorNombre(e.target.value)
                .then((resp) => {
                    console.log(resp.data.data)
                    setCurso(resp.data.data)
                    setLoadingBuscarCurso(false)
                    
                })
                .catch((error) => console.log(error))
        }
    }

    useEffect(() => {
        if (tableIsVisible) {
            navigate('/asistenciaplanilla', { state: filaCurso });
        }
    }, [tableIsVisible, filaCurso, navigate]); // Dependencias del useEffect
    

    console.log(filaCurso)
    return(
        <Container>
            
            <Row className="bg-dark  rounded-top-4 align-items-end pt-2 mt-3">
                <h2 className="text-white" style={{fontVariant:'all-small-caps'}}>Buscar Cursos</h2>
            </Row>
            <Row className=" align-middle border border-dark pt-4 pb-4 ps-2">
                <Col xs={12} md={2} className="mt-2 text-center">
                    <Form.Check
                        inline
                        label="Nombre"
                        name="radio-busqueda1"
                        value="Nombre"
                        type="radio"
                        onClick={handleSelect} />
                </Col>
                <Col xs={12} md={2} className="mt-2 text-center">
                    <Form.Check
                        inline
                        label="N° Curso"
                        name="radio-busqueda1"
                        value="nro_curso"
                        type="radio"
                        onClick={handleSelect} />
                </Col>
                <Col xs={12} md={8}>
                    <Row>
                        <Col md={12} xs={12}>
                            <Form.Group className="mb-6" controlId="buscar">

                                <Form.Control type="text" placeholder={`Ingresa ${criterioBusqueda}`} onChange={onInputCurso} />
                            </Form.Group>
                        </Col>

                    </Row>

                </Col>
            </Row>
            {loadindBuscarCurso&&<Espera/>}
            {!loadindBuscarCurso&&<Tabla_Cursos 
            curso={curso} 
            setFilaCurso={setFilaCurso} 
            setVisible={setTableIsVisible} 
            setAlumnosxCruso={setAlumnosxCurso}
            setLoadingAlumnosMatriculados={setLoadingAlumnosMatriculados}
            />}
            {/*tableIsVisible &&
            <>
            <Row className='mt-5 border-bottom border-dark'>
                <h2>Horarios de Materias</h2>
            </Row>
            <Row className='mb-3 mt-3 d-flex justify-content-end align-middle'>
                <InputGroup className="d-flex justify-content-end align-middle mb-3 w-50">
                    <Form.Control
                    placeholder="Buscar por materia, profesor, horario o comisión"
                    aria-label="Buscador"
                    aria-describedby="basic-addon2"
                    />
                    <Button variant="dark outline-dark" id="button-addon2">
                        <FaSearch />
                    </Button>
                </InputGroup>
            </Row>
            <Row>
                <Table>
                    <thead>
                        <tr>
                        <th>Materia</th>
                        <th>Profesor</th>
                        <th>Horarios</th>
                        <th>Acción</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td> Cultivador de Hongos (Comisión: 06) <br/> <span style={{color:'red'}}>Teórico / Práctico</span></td>
                        <td>Sebastián Centurión</td>
                        <td>Lunes de 18.00hs a 20.00hs <br/> Miércoles de 18.00hs a 20.00hs</td>
                        <td className='align-middle'><Button onClick={handleRedirect} variant='secondary' size='sm'>Asistencia</Button></td>
                        </tr>
                        <tr>
                        <td> Diseño web (Comisión: 02) <br/><span style={{color:'red'}}>Teórico / Práctico</span></td>
                        <td>Gustavo Iglesias</td>
                        <td>Lunes de 15.00hs a 17.00hs <br/> Miércoles de 15.00hs a 17.00hs</td>
                        <td className='align-middle'><Button variant='secondary' size='sm'>Asistencia</Button></td>
                        </tr>
                        <tr>                
                        <td>Cocina Saludable (Comisión: 03) <br/><span style={{color:'red'}}>Teórico / Práctico</span></td>
                        <td>Sebastián Centurión</td>
                        <td>Lunes de 18.00hs a 20.00hs <br/> Miércoles de 18.00hs a 20.00hs</td>
                        <td className='align-middle'><Button variant='secondary' size='sm'>Asistencia</Button></td>
                        </tr>
                        <tr>
                        <td>Fotografía (Comisión: 04) <br/><span style={{color:'red'}}>Teórico / Práctico</span></td>
                        <td>Raúl Waridel</td>
                        <td>Lunes de 18.00hs a 20.00hs <br/> Miércoles de 18.00hs a 20.00hs</td>
                        <td className='align-middle'><Button variant='secondary' size='sm'>Asistencia</Button></td>
                        </tr>
                        <tr>
                        <td>Carpintería de Obra Fina (Comisión: 05) <br/><span style={{color:'red'}}>Práctico</span></td>
                        <td>Alejandro Plenkovic</td>
                        <td>Lunes de 18.00hs a 20.00hs <br/> Miércoles de 18.00hs a 20.00hs</td>
                        <td className='align-middle'><Button variant='secondary' size='sm'>Asistencia</Button></td>
                        </tr>
                        <tr>
                        <td>Manipulador de alimentos (Comisión: 06) <br/> <span style={{color:'red'}}>Teórico</span></td>
                        <td>Daniela Laboranti</td>
                        <td>Lunes de 18.00hs a 20.00hs <br/> Miércoles de 18.00hs a 20.00hs</td>
                        <td className='align-middle'><Button variant='secondary' size='sm'>Asistencia</Button></td>
                        </tr>
                    </tbody>
                </Table>
            </Row>
            <Row>
                <Col md={1}>
                    <DropdownButton
                    as={ButtonGroup}
                    id="dropdown-button"
                    size="sm"
                    variant="secondary"
                    title="10"
                    flip= {true}
                    >
                        <Dropdown.Item eventKey="2">20</Dropdown.Item>
                        <Dropdown.Item eventKey="3">30</Dropdown.Item>
                        <Dropdown.Item eventKey="4">50</Dropdown.Item>
                    </DropdownButton>
                </Col>
                <Col></Col>
                <Col md={2} >
                    <Pagination size="sm">{items}</Pagination>
                </Col>
            </Row>
            </>*/}
        </Container>
    )
}

export default  Asistencia