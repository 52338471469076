import './App.css';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Navbar, Nav } from 'react-bootstrap';
import logo from "./assest/logo120x120.png"
import authService from './service/auth-service';
import Registro from './pages/Registro';
import AfterRegister from "./pages/AfterRegister";
import Login from './pages/Login';
import Matricular from './pages/Matricular';
import CrearAlumno from './pages/CrearAlumno';
import Alumnos from './pages/Alumnos2';
import Nota from './pages/Notas';
import Curso from './pages/Curso';
import CrearCurso from './pages/CrearCurso';
import Asistencia from './pages/Asistencia';
import AsistenciaPlanilla from './pages/AsistenciaPlanilla';
import './pages/AsistenciaPlanilla.css';
import AsistenciaHistorial from './pages/AsistenciaHistorial';

function App() {
  const [currentUser, setCurrentUser] = useState(undefined);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [showProfesorBoard, setShowProfesorBoard] = useState(false);
  const [showAlumnosBoard, setShowAlumnosBoard] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const user = authService.getCurrentUser();
    if (user) {
      setCurrentUser(user);
      setShowAdminBoard(user.roles?.includes("ROLE_ADMIN"));
      setShowProfesorBoard(user.roles?.includes("ROLE_PROFESOR"));
      setShowAlumnosBoard(user.roles?.includes("ROLE_ALUMNO"));
    }
  }, []);

  const logout = () => {
    authService.logout();
    navigate("/");
    window.location.reload();
  }




  return (
    <Container>
      <Navbar className="bg-body-tertiary justify-content-between ps-3 pe-3 pt-2 pb-0 mt-2" expand="lg" variant='transparente'>

        <Navbar.Brand href="/entrada">
          <img
            href="/"
            alt="logo"
            src={logo}
            width="60px"
            height="60px"
            style={{marginRight: '10px'}}
          />
          CENTRO DE FORMACIÓN LABORAL N° 401
        </Navbar.Brand>
        {currentUser ? (
            <Nav className='justify-content-between'>
              <Nav.Link onClick={logout} className='text-dark fs-5'>
                  Cerrar Sesión
                </Nav.Link>
            </Nav>) :
            (
              <Nav className='justify-content-between'>
                <Nav.Link href="/login" className='text-dark fs-5'>
                  Ingresá
                </Nav.Link>
                <Nav.Link href="/registro" className='text-dark fs-5'>
                  Registrate
                </Nav.Link>
              </Nav>
            )
          }
        </Navbar>
        <Navbar className='bg-body-tertiary border-bottom mt-0' expand="lg">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
          <Nav variant='underline' className='mx-auto border-top pt-1'>

            {showProfesorBoard && <Nav.Link href="/matricular" className='me-4'>
              Ficha de curso
            </Nav.Link>}

            {showProfesorBoard &&
              <Nav.Link href="/alumnos" className='me-4'>Alumnos</Nav.Link>
            }

            {showProfesorBoard &&
              <Nav.Link href="/notas" className='me-4'>Acta de examen</Nav.Link>
            }
            {showProfesorBoard &&
              <Nav.Link href="/curso" className='me-4'>Cursos</Nav.Link>
            }
            {showProfesorBoard &&
              <Nav.Link href="/asistencia" className='me-4'>Asistencia</Nav.Link>
            }
            
          </Nav>

          

        </Navbar.Collapse>

      </Navbar>

      <Routes>
        <Route path="/login" element={<Login titulo={"Ingresar a CFL401SADA"} />} />
        <Route path="/registro" element={<Registro />} />
        <Route path="/afterregister" element={<AfterRegister />} />
        <Route path='matricular' element={<Matricular />} />
        <Route path='crearalumno' element={<CrearAlumno />} />
        <Route path='crearcurso' element={<CrearCurso />} />
        <Route path='alumnos' element={<Alumnos />} />
        <Route path='notas' element={<Nota />} />
        <Route path='curso' element={<Curso />} />
        <Route path='asistencia' element={<Asistencia />} />
          <Route path='/asistenciaplanilla' element={<AsistenciaPlanilla />}/>
            <Route path='asistenciahistorial' element={<AsistenciaHistorial />}/>
            
      </Routes>
    </Container>
  );
}

export default App;
