import React from "react"
import './curso.css'
import { useEffect } from "react"
import { useState } from "react"
import axios from "axios"
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import cflService from "../service/cfl-service"
import BootstrapTable from "react-bootstrap-table-next"
import moment from 'moment';
import { FaEdit,FaUserPlus } from 'react-icons/fa';
import Tabla_Alumnos from "../components/Tabla_Alumnos"
import { useNavigate } from 'react-router-dom';
import { Button, Spinner,ProgressBar, Row,Col } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { FaTrashCan } from 'react-icons/fa6';
import { width } from "@fortawesome/free-solid-svg-icons/fa0"


export default () => {

        const [curso, setCursos] = useState([])
        const [search, setSearch] = useState("")
        const [fila, setFila] = React.useState([])
        const [isLoading, setIsLoading] = React.useState(true)
        const [progress, setProgress] = React.useState(20); 
        const [form, setForm] = React.useState(false)
        const navigate = useNavigate();

        console.log('Curso', curso)

        const newCurso=()=>{
            navigate("/crearcurso")
            console.log("boton")
          }


          const editRow = (row) => {
            let localidad = row.localidad;
            let nacionalidad = row.nacionalidad
            row.localidad = { label: localidad, value: localidad };
            row.nacionalidad = { label: nacionalidad, value: nacionalidad };
            row.provincia = { label: row.provincia, value: row.provincia };
            row.municipio = { label: row.partido, value: row.partido };
            row.fechaNacimiento = moment(row.fechaNacimiento).format("YYYY-MM-DD")
            setFila(row)
            setForm(true)
          }
                
          useEffect(() => {
            setProgress(60);
            cflService.buscarTodosLosCursos()
                .then((resp) => {
                    console.log(resp.data.data); // ACA ESTABA EL BUG
                    setCursos(resp.data.data)
                    setIsLoading(false); 
                    setProgress(100);
                })
                .catch((error) => {
                    console.log(error)
                    setIsLoading(false); // Asegúrate de cambiar isLoading a falso en caso de error también
                });
        }, []);
        

        const actionFormatter = (cell, row) => {
            return (
              <div className='accion-table-alumno'>
                <Button as='div' variant="primary" className='mr-2' onClick={() => editRow(row)}>
                  <FaEdit />
                </Button>
                <Button as='div' variant="danger" onClick={() => deleteRow(row)}>
                 <FaTrashCan />
                </Button>
              </div>
            );
          };

        const deleteRow = async (data) => {
            Swal.fire({
                title: '¿Estás seguro/a? <br/> Esta acción es irreversible',
                html: `<p>Vas a eliminar el curso: <b>${data.nombre}</b> de forma permanente la base de datos.</p>`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#198754',
                cancelButtonColor: '#d33',
                confirmButtonText: '¡Sí, borrar!',
                cancelButtonText:"Cancelar",
            }) .then((result) => {
                if (result.isConfirmed){
                    Swal.fire(
                        'Borrado!',
                        `El curso ${data.nombre} ya no esta en la base de datos`,
                        'success'
                    )
                    cflService.deleteCurso(data.idCurso)
                        .then((resp) => {
                            if(resp.data.success) {
                                console.log("borrado")
                                window.location.reload();
                            }
                        })
                        .cath((error) => {
                            console.log(error)
                        })
                }
            })
        } 

        const columnsCursos = [
            {
                dataField: 'idCurso',
                text: 'ID',
                headerStyle: { backgroundColor: 'black', color: 'white',  verticalAlign: 'middle'}
            },
            {
                dataField: 'nombre',
                text: 'Nombre del curso',
                filter: textFilter({
                    placeholder: "Filtrar por nombre",
                    text: "Filtrar por nombre"
                }),
                headerStyle: { backgroundColor: 'black', color: 'white',  verticalAlign: 'middle'}
            },
            {
                dataField: 'fechaInicio',
                text: 'Inicio del curso',
                headerStyle: { backgroundColor: 'black', color: 'white',  verticalAlign: 'middle', textAlign: 'center'}
            },
            {
                dataField: 'fechaFinalizacion',
                text: 'Finalizacion del curso',
                headerStyle: { backgroundColor: 'black', color: 'white',  verticalAlign: 'middle', textAlign: 'center'}
            },
            {
                dataField: 'cupo',
                text: 'Cupo',
                headerStyle: { backgroundColor: 'black', color: 'white',  verticalAlign: 'middle', textAlign: 'center'}
            },
            {
                dataField: 'actions',
                text: 'Acciones',
                formatter: actionFormatter, 
                style: {
                  width: '100px', 
                },
                headerStyle: { width: '110px', backgroundColor: 'black', color: 'white',  verticalAlign: 'middle', textAlign: 'center'},
              },
        ];

    return (

    <>
        {isLoading &&   
        <ProgressBar animated now={progress} style={{marginTop:"3em"}} label={`${progress} %`}/>
        }
        {!form && !isLoading &&
        <>
            <div className="container-cursos-page">
                <div className="container-table">
                      <Row >
                            <Col style={{display:"flex",justifyContent:"end"}}>
                                <button style={{padding:"2em 3em 2em 0",borderColor:"transparent",backgroundColor:"transparent"}} onClick={newCurso}>
                                <FaUserPlus size={48}/>
                                </button>
                            </Col>
                        </Row> 
                    <BootstrapTable
                        keyField='idCurso'
                        data={curso}
                        columns={columnsCursos}
                        pagination={paginationFactory()}
                        filter={filterFactory()}          
                        />
                </div>
            </div>
        </>
        }
        </>

    )
}