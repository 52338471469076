import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import cflService from '../service/cfl-service'
import Swal from 'sweetalert2';
import * as yup from 'yup';
import { Navigate, useNavigate } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Button, Container, Row, Col } from 'react-bootstrap';


const FormCursoDos = ({initialValues}) => {

    const [loading, setLoading] = useState(false);
    const [nombre, setNombre] = useState("");
    const [fechaInicio, setFechaInicio] = useState("");
    const [fechaFinalizacion, setFechaFinalizacion] = useState("");
    const [cupos, setCupos] = useState("");
    const navigate=useNavigate();
    


    const asterico = <span id="i4" className="rAXpye" aria-label="Pregunta obligatoria">*</span>

    // Aca voy a crear mi funcion para enviar los datos a la bdd
    
    const handleSubmit = (values) => {
        setLoading(true)
        console.log(values);
        const data = {
            nombre: values.nombre,
            fechaInicio: values.fechaInicio,
            fechaFinalizacion: values.fechaFinalizacion,
            cupos: values.cupos
        }
        console.log(`La data es : ${JSON.stringify(data)}`)
        // Aca tengo que llamar a la funcion para cargar el curso 
        cflService.crearCurso(data) // no se si funciona igual
        .then((resp) => {
            setLoading(false)
            if (resp.data.success) {
                Swal.fire({
                    title:'Exito',
                    text:`Se registro con exito`,
                    icon:"success",
                    showConfirmButton:false,
                    showCloseButton:true
                })
                .then((resp) =>{
                    navigate("/cursos")
                })
                
            }
            else {
                Swal.fire({
                    title:'Cuidado!',
                    text:`El curso ya existe`,
                    icon:"warning",
                    showConfirmButton:false,
                    showCloseButton:true
                })
            }
            console.log(resp)
        })
        .catch((error) => {
            setLoading(false)
            Swal.fire({
                title:'Cuidado!',
                text:`Revise su conexion!`,
                icon:"error",
                showConfirmButton:false,
                showCloseButton:true
            })
        })

    }

    // funcion para las validaciones del formulario, Utilizando la libreria YUP

    const validationSchema = yup.object().shape({
        nombre: yup.string().required('El nombre es requerido'),
        fechaInicio: yup.date().required('La fecha de inicio es requerida'),
        fechaFinalizacion: yup.date().required('La fecha de finalizacion es requerida'),
        cupos: yup.number().required('La cantidad de cupos es requerida')
    })



    return (

        <Container fluid className='border border-dark'>
            <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
                
                {({touched, errors}) => (     
                <Form >
                    <Row className='mt-4'>
                        <Col>
                            <label htmlFor='nombre' className='form-label'>Nombre{asterico}</label>
                            <Field
                                type="text"
                                placeholder="Ingrese nombre del curso"
                                id="nombre"
                                name='nombre'

                                className={`form-control ${touched.nombre && errors.nombre ? 'is-invalid' : touched.nombre && !errors.nombre ? 'is-valid' : ''
                                }`}
                            />
                            <ErrorMessage
                                name="nombre"
                                component="div"
                                className="text-danger"
                            />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col md={5}>
                        <label htmlFor='Fecha de inicio' className='form-label'>Fecha de inicio{asterico}</label>
                                <Field
                                    type="text"
                                    placeholder="Ingrese fecha de inicio"
                                    id="fechaInicio"
                                    name='fechaInicio'
                                    className={`form-control ${touched.fechaInicio && errors.fechaInicio ? 'is-invalid' : touched.fechaInicio && !errors.fechaInicio ? 'is-valid' : ''
                                    }`}
                                />
                                <ErrorMessage
                                    name="fechaInicio"
                                    component="div"
                                    className="text-danger"
                                />
                        </Col>
                        <Col md={5}>
                        <label htmlFor='Fecha de finalizacion' className='form-label'>Fecha de finalización{asterico}</label>
                                <Field
                                    type="text"
                                    placeholder="Ingrese fecha de finalización"
                                    id="fechaFinalización"
                                    name='fechaFinalización'
                                    className={`form-control ${touched.fechaFinalizacion && errors.fechaFinalizacion ? 'is-invalid' : touched.fechaFinalizacion && !errors.fechaFinalizacion ? 'is-valid' : ''
                                     }`}
                                />
                                <ErrorMessage
                                    name="fechaFinalización"
                                    component="div"
                                    className="text-danger"
                                />
                        </Col>
                        <Col md={2}>
                        <label htmlFor='Cupo' className='form-label'>Cantidad de cupos{asterico}</label>
                                <Field
                                    type="text"
                                    placeholder="Nro de cupos"
                                    id="cupo"
                                    name='cupo'
                                    className={`form-control ${touched.cupos && errors.cupos ? 'is-invalid' : touched.cupos && !errors.cupos ? 'is-valid' : ''
                                     }`}
                                />
                                <ErrorMessage
                                    name="cupo"
                                    component="div"
                                    className="text-danger"
                                />
                        </Col>
                    </Row>
                    <Row className='mt-3 mb-3'>
                        <Col className='text-center'>
                            <Button variant='success'  type='submit' disabled={loading}>{loading?"Cargando":"Enviar"}</Button>
                        </Col>
                    </Row>
                </Form>
                )}
            </Formik>
        </Container>
    )


}


export default FormCursoDos;