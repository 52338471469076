import { Table, Form, Button } from "react-bootstrap";
import { FaTrashCan } from "react-icons/fa6";
import cflService from "../service/cfl-service";
import '../components/TablaAlumnosNotas.css'
import Swal from 'sweetalert2';

const TablaAlumnosNotas = ({ alumnoxCurso, setAlumnosxCurso, filacurso }) => {

    console.log('AlumnoXCurso', alumnoxCurso)
    console.log('fila curso', filacurso.idCurso)

    const deleteRow = (data, idCurso) => {
        const idAlumno = data.idAlumnos;
        Swal.fire({
            title: '¿Estás seguro/a? <br/> Esta acción es irreversible',
            html: `<p>Vas a desmatricular a: <b>${data.apellido}, ${data.nombre}</b> de forma permanente del curso: <i>${filacurso.nombre}.</i></p>`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#198754',
            cancelButtonColor: '#d33',
            confirmButtonText: '¡Sí, borrar!',
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                setAlumnosxCurso(alumnoxCurso.filter(item => item.idAlumnos != data.idAlumnos))
                Swal.fire(
                    'Borrado!',
                    `El alumno ${data.nombre}  ${data.apellido} ya no esta matriculado `,
                    'success'
                )
                cflService.enviarEstadoAlumno( {idCurso, idAlumno, sqlDelete: 1} )
                    .then((resp) => {
                        console.log("Alumno eliminado de la base de datos");
                    })
                    .catch((error) => console.log("Error al eliminar el alumno:", error));
            }
        });
    };

    // Función para calcular el promedio
    const calcularPromedio = (teoria, practica) => {
        if (teoria !== undefined && practica !== undefined) {
            return Math.round((teoria + practica) / 2);
        }
        return 0; // Devuelve 0 si no hay datos disponibles
    };
    
    const handleTeoria = (e, data, cursoId) => {
        const { idAlumnos, alumnosHasCursosByIdAlumnos } = data;
        const newTeoriaValue = e.target.value;
    
        // Validar que el valor esté dentro del rango de 0 a 100
        if (newTeoriaValue >= 0 && newTeoriaValue <= 100) {
            // Buscar el objeto correspondiente para el curso en el array de inscripciones
            const inscripcionEnCurso = alumnosHasCursosByIdAlumnos.find(
                (inscripcion) => inscripcion.cursoIdCurso === cursoId
            );
    
            if (inscripcionEnCurso) {
                // Actualizar el valor de teoría en la inscripción del curso
                inscripcionEnCurso.teoria =parseInt(newTeoriaValue) ;
                
                // Calculamos el promedio automáticamente
                inscripcionEnCurso.nota = calcularPromedio(
                inscripcionEnCurso.teoria,
                inscripcionEnCurso.practica
            );
    
                // Actualizar el array de inscripciones en el objeto data
                const updatedAlumnosHasCursos = data.alumnosHasCursosByIdAlumnos.map((inscripcion) =>
                    inscripcion.cursoIdCurso === cursoId ? inscripcionEnCurso : inscripcion
                );
    
                // Actualizar el objeto data con el nuevo array de inscripciones
                const updatedAlumno = { ...data, alumnosHasCursosByIdAlumnos: updatedAlumnosHasCursos };
    
                // Actualizar el array de alumnos con el nuevo objeto data
                setAlumnosxCurso(alumnoxCurso.map((alumno) => (alumno.idAlumnos === idAlumnos ? updatedAlumno : alumno)));
            }
        }
    }
    
    const handlePractica = (e, data, cursoId) => {
        const { idAlumnos, alumnosHasCursosByIdAlumnos } = data;
        const newPracticaValue = e.target.value;
    
        // Validar que el valor esté dentro del rango de 0 a 100
        if (newPracticaValue >= 0 && newPracticaValue <= 100) {
            // Buscar el objeto correspondiente para el curso en el array de inscripciones
            const inscripcionEnCurso = alumnosHasCursosByIdAlumnos.find(
                (inscripcion) => inscripcion.cursoIdCurso === cursoId
            );
    
            if (inscripcionEnCurso) {
                // Actualizar el valor de teoría en la inscripción del curso
                inscripcionEnCurso.practica = parseInt(newPracticaValue);

                // Calculamos el promedio automáticamente
                inscripcionEnCurso.nota = calcularPromedio(
                inscripcionEnCurso.teoria,
                inscripcionEnCurso.practica
            );
    
                // Actualizar el array de inscripciones en el objeto data
                const updatedAlumnosHasCursos = data.alumnosHasCursosByIdAlumnos.map((inscripcion) =>
                    inscripcion.cursoIdCurso === cursoId ? inscripcionEnCurso : inscripcion
                );
    
                // Actualizar el objeto data con el nuevo array de inscripciones
                const updatedAlumno = { ...data, alumnosHasCursosByIdAlumnos: updatedAlumnosHasCursos };
    
                // Actualizar el array de alumnos con el nuevo objeto data
                setAlumnosxCurso(alumnoxCurso.map((alumno) => (alumno.idAlumnos === idAlumnos ? updatedAlumno : alumno)));
            }
        }
    };


    const handleEgresado = (e, data, cursoId) => {
        const { idAlumnos, alumnosHasCursosByIdAlumnos } = data;
        const newValue = e.target.value;

        // Buscar el objeto correspondiente para el curso en el array de inscripciones
        const inscripcionEnCurso = alumnosHasCursosByIdAlumnos.find(
        (inscripcion) => inscripcion.cursoIdCurso === cursoId
        );
    
        if (inscripcionEnCurso) {
            // Actualizar el valor de teoría en la inscripción del curso
            inscripcionEnCurso.nroEgresado =parseInt(newValue) ;
    
            // Actualizar el array de inscripciones en el objeto data
            const updatedAlumnosHasCursos = data.alumnosHasCursosByIdAlumnos.map((inscripcion) =>
            inscripcion.cursoIdCurso === cursoId ? inscripcionEnCurso : inscripcion
            );
    
            // Actualizar el objeto data con el nuevo array de inscripciones
            const updatedAlumno = { ...data, alumnosHasCursosByIdAlumnos: updatedAlumnosHasCursos };
    
            // Actualizar el array de alumnos con el nuevo objeto data
            setAlumnosxCurso(alumnoxCurso.map((alumno) => (alumno.idAlumnos === idAlumnos ? updatedAlumno : alumno)));
        }
    }
    
    return (
        <Table striped  hover className="mt-3" responsive  size="sm" >
            <thead className="" >
                <tr>
                    <th className="bg-dark text-white">Apellido</th>
                    <th className="bg-dark text-white">Nombres</th>
                    <th className="bg-dark text-white text-center">Teoría</th>
                    <th className="bg-dark text-white text-center">Práctica</th>
                    <th className="bg-dark text-white text-center">Final</th>
                    <th className="bg-dark text-white text-center">N° Egresado</th>
                    <th className="bg-dark text-white text-center">Baja</th>
                </tr>
            </thead>
            <tbody>
                {alumnoxCurso.map((data, i) => {
                    return (
                        <tr key={i}>
                            <td className="text-uppercase align-middle">{data.apellido}</td>
                            <td className="align-middle">{data.nombre}</td>
                            <td>
                                <Form.Group className="mt-1 w-100">
                                    <Form.Control 
                                        type="number" 
                                        className="form-control-table text-center"
                                        min="0" 
                                        max="100" 
                                        onChange={(e)=>{handleTeoria(e,data, filacurso.idCurso)}} 
                                        value={data.alumnosHasCursosByIdAlumnos.find((teoria)=>teoria.cursoIdCurso===filacurso.idCurso)?.teoria}
                                        title="La escala de calificaciones va de 0 a 100"
                                    />
                                </Form.Group>
                                
                            </td>
                            <td>
                                <Form.Group className="mt-1 w-100">
                                    <Form.Control 
                                        type="number"
                                        className="form-control-table text-center"
                                        min="0" 
                                        max="100" 
                                        onChange={(e)=>{handlePractica(e,data, filacurso.idCurso)}} 
                                        value={data.alumnosHasCursosByIdAlumnos.find((practica)=>practica.cursoIdCurso===filacurso.idCurso)?.practica}
                                        title="Las notas van desde 0 hasta 100"
                                    />
                                </Form.Group>
                            </td>
                            <td>
                                <Form.Group className="mt-1 w-100" >
                                    <Form.Control 
                                        type="number" 
                                        className="form-control-table text-center"
                                        min="0" 
                                        max="100" 
                                        value={data.alumnosHasCursosByIdAlumnos.find((nota)=>nota.cursoIdCurso===filacurso.idCurso)?.nota}
                                        readOnly
                                    />
                                </Form.Group>
                            </td>
                            <td>
                                <Form.Group className="mt-1 w-100" >
                                    <Form.Control 
                                        type="number" 
                                        className="form-control-table text-center"
                                        min="0" 
                                        max="100" 
                                        onChange={(e)=>{handleEgresado(e,data, filacurso.idCurso)}} 
                                        value={data.alumnosHasCursosByIdAlumnos.find((nroEgresado)=>nroEgresado.cursoIdCurso===filacurso.idCurso)?.nroEgresado}
                                    />       
                                </Form.Group>
                            </td>
                            <td className="text-center align-middle">
                                <Button 
                                size="sm" 
                                variant="danger"
                                onClick={() => deleteRow(data, filacurso.idCurso)}
                                title="Eliminar Registro">
                                    <FaTrashCan />
                                </Button>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}
export default TablaAlumnosNotas;