import Swal from 'sweetalert2';

const AlertaError = ({ title, message, button, timer}) => {
  Swal.fire({
    icon: 'error',
    title: title || 'Error',
    text: message || '¡Algo salió mal!',
    showConfirmButton: button !== undefined ? button : true, // Por defecto mostrar botón
    customClass: {
      confirmButton: 'custom-confirm-button', // Clase personalizada para el botón
    },
    ...(timer && { timer }),
   /* willOpen: () => {
      // Aplicar estilos personalizados cuando el popup esté abierto
      const customStyles = document.querySelector('.custom-confirm-button');
      if (customStyles) {
        customStyles.style.backgroundColor = 'black';  // Fondo negro
        customStyles.style.color = 'white';            // Texto blanco
        customStyles.style.border = 'none';            // Sin borde
      }
    },*/
  });
};

export default AlertaError;