import React from 'react';
import FormAlumno from '../components/FormAlumno';
import { Col, Container, Row } from 'react-bootstrap';

const initialValues = {
    nombre: "",
    apellido: "",
    dni: "",
    sexo: '',
    fechaNacimiento: '',
    nacionalidad: "",
    lugar_nacimiento: "",
    telefono: "",
    calle: "",
    numero: "",
    localidad: "",
    partido: "",
    email: "",
    provincia: null,
    municipio: null,
    localidad: null,
    nacionalidad: null
}
const CrearAlumno=()=>{
    return(
        <Container className='pb-3' md={12}>
            <Container fluid>
                <Row className="bg-dark  rounded-top-4 align-items-end pt-2 mt-3">
                    <Col md={6}>
                        <h2 className="text-white" style={{fontVariant:'all-small-caps'}}>Formulario</h2>
                    </Col>
                    <Col md={6}>
                        <h4 className="text-white text-end align-middle" style={{fontWeight:'bold'}}>Cargar nuevo alumno</h4>
                    </Col>
                </Row>
                <Row className=" align-middle border border-dark">
                    <h5 className="text-dark text-center align-middle" style={{fontWeight:'bold', fontVariant: 'all-petite-caps'}}>Ingresar datos</h5>
                </Row>
            </Container>
            <Container fluid className='border border-dark pb-3'>
                <FormAlumno initialValues={initialValues} />
            </Container>
        </Container>
    )
}
export default  CrearAlumno