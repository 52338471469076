import React from "react";


const AfterRegister =()=>{
    return (
        <div className="text-center mt-5 titulo">
        <h1>Verifica tu mail , para confirmar tu registro</h1>
        </div>
    )
}
export default AfterRegister