import { Table, Button } from "react-bootstrap";
import { FaTrashCan } from "react-icons/fa6";
import cflService from "../service/cfl-service";
import Swal from 'sweetalert2'
const TablaAlumnosxCurso = ({ alumnoxCurso, setAlumnosxCurso, filacurso }) => {

    const deleteRow = (data) => {
        Swal.fire({
            title: '¿Estás seguro/a? <br/> Esta acción es irreversible',
            html: `<p>Vas a eliminar los datos de: <b>${data.apellido}, ${data.nombre}</b> de forma permanente</p>`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#198754',
            cancelButtonColor: '#d33',
            confirmButtonText: '¡Sí, borrar!',
            cancelButtonText:"Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                setAlumnosxCurso(alumnoxCurso.filter(item => item.idAlumnos != data.idAlumnos))
                Swal.fire(
                    'Borrado!',
                    `El alumno ${data.nombre }  ${data.apellido} ya no esta matriculado `,
                    'success'
                )
                cflService.deleteMatricular(filacurso.idCurso, data.idAlumnos)
                    .then((resp) => {
                       console.log("borrardo")
                    })
                    .catch((error) => console.log(error))
            }
        })
    }

    return (
        <Table striped hover className="mt-3" responsive>
            <thead>
                <tr>
                    <th className="text-center bg-dark text-white">DNI</th>
                    <th className="bg-dark text-white">Nombres</th>
                    <th className="bg-dark text-white">Apellidos</th>
                    <th className="bg-dark text-white">Email</th>
                    <th className="text-center bg-dark text-white">Accion</th>
                </tr>
            </thead>
            <tbody>

                {alumnoxCurso.map((data, i) => {
                    return (
                        <tr key={i}>
                            <td className="text-center">{data.dni}</td>
                            <td>{data.nombre}</td>
                            <td>{data.apellido}</td>
                            <td>{data.email}</td>
                            <td className="text-center">
                                <div className="center-accion">
                                    <Button 
                                    variant="danger" 
                                    onClick={() => deleteRow(data)}
                                    title="Eliminar Registro">
                                        <FaTrashCan />
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}
export default TablaAlumnosxCurso;