import {React, useEffect, useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {Table, Button, Container, Row, Col, Accordion, ListGroup, Form, Toast, Spinner} from 'react-bootstrap';
import {FaPrint} from 'react-icons/fa'
import cflService from "../service/cfl-service";
import { FaRegCalendarCheck } from "react-icons/fa";
import { FaRegCircleUser } from "react-icons/fa6";
import { RiArrowGoBackFill } from "react-icons/ri";
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es')

const AsistenciaHistorial = () => {
    const location = useLocation();
    const dataReceived = location.state;
    const [cohorteHistorico, setCohorteHistorico] = useState([])
    const idCurso = location.state?.state?.idCurso;
    const [alumnos, setAlumnos] = useState([])
    const [showOverlay, setShowOverlay] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const navigate = useNavigate(); // Esto te permite navegar entre páginas

    console.log('curso', idCurso)

    const handleVolver = () => {
        navigate(-1); // Esto volverá a la página anterior
      };
    
    useEffect(() => {
        let idCurso =  location.state?.state?.idCurso;
        cflService.traerAsistenciaHistorica(idCurso)
        .then((response)=>{
            console.log('cohortes históricas', response.data.data)
            if (response.data.success){
                const cohorteshistoricos = response.data?.data?.sort((a, b) => new Date(b.fecha) - new Date(a.fecha));
                setCohorteHistorico(cohorteshistoricos)
            }
        })
        .catch ((e)=>{
            console.log(e)
        })
    }, [])
    console.log('data receive', location)

    const asistenciasAlumnos = cohorteHistorico.flatMap(cohorte => cohorte.asistencias);
    console.log('Asistencia Alumnos', asistenciasAlumnos)

    useEffect(() => {
            console.log("idCurso:", idCurso);
            cflService.getalumnosxcurso(idCurso)
            .then((response) => {
                console.log("Alumnos por Curso:", response.data); // Opcional, si deseas retornar los datos.
                setAlumnos(response.data?.data || []);
            })
            .catch((error) => {
                console.error("Error obteniendo los alumnos por curso:", error);
            });
        }, [idCurso]);

        // Verificamos la estructura de los datos de 'alumnos' antes de usar 'map'
    console.log('Alumnos:', alumnos);

    // Asegurarnos de que alumnos sea un array antes de usar map
    const alumnosMap = Array.isArray(alumnos) ? Object.fromEntries(alumnos.map(a => [a.id, a])) : {};

    // Reemplazar idAlumno con nombre y apellido
    const cohorteHistoricoConNombres = cohorteHistorico.map(cohorte => {
        return {
            ...cohorte,
            asistencias: cohorte.asistencias.map(asistencia => {
                const alumno = alumnosMap[asistencia.idAlumno];
                return {
                    ...asistencia,
                    nombre: alumno ? `${alumno.nombre}` : 'Alumno no encontrado',
                    apellido: alumno ? `${alumno.apellido}` : 'Alumno no encontrado',
                };
            }),
        };
    });

    console.log('Cohorte con Nombres', cohorteHistoricoConNombres);

    const handleModificarAsistencia = (idAsistencia, idAlumno, idCohorte, presente) => {
        const data = {
            idAlumno,
            idCohorte,
            presente: !presente
        };

        setShowOverlay(true);

        cflService.actualizarAsistencia(idAsistencia, data)
        .then ((response)=> {
            console.log('Asistencia Actualizada', response.data)
            
            setCohorteHistorico((prev) =>
                prev.map((cohorte)=>({
                    ...cohorte,
                    asistencias: cohorte.asistencias.map((asistencia)=>
                        asistencia.idAsistencia === idAsistencia
                            ?{...asistencia, presente: !presente}
                            :asistencia
                        )
                }))
            );
            setShowToast(true);
            setTimeout(()=> setShowToast(false),3000);
        })
        .catch ((error) =>{
            console.log("Error actualizando la existencia", error);
        })
        .finally(() => {
            setShowOverlay(false); 
        });
    };

    return (

        <Container className="mt-5" md={12} >
            <Toast
                position='middle-center'
                onClose={() => setShowToast(false)}
                show={showToast}
                delay={3000}
                autohide
                bg="light"
                style={{ position: 'fixed', top: 20, right: 20 }}
            >
                <Toast.Header>
                    <strong className="me-auto">Confirmación</strong>
                </Toast.Header>
                <Toast.Body>Asistencia actualizada correctamente.</Toast.Body>
            </Toast>
            <Container >
                <Row className="bg-dark border-bottom rounded-top-4 align-items-end pt-2">
                    <Col  className="text-end pe-3">
                        <h1 className="text-light" style={{fontWeight:'bold'}}>Historial de Asistencia</h1> 
                    </Col>
                </Row>
            </Container>
            <Container className="border-bottom border-dark">
                <Row>
                    <Col>
                        <h3 style={{fontVariant:'all-small-caps'}}>{dataReceived.state?.nombre || ''}</h3>
                    </Col>
                </Row>
            </Container>
            <Container className="mt-4 ">
                <Row className="border-bottom border-dark border-2 justify-content-end">
                    <Col md={12}>
                        <h2 className=' text-dark' style={{fontVariant:'small-caps', fontWeight:'600'}}>Fechas</h2>
                    </Col>
                </Row>
            </Container>
            {cohorteHistoricoConNombres.map((historico, index)=>(
            <Accordion key={index} flush className="border-bottom border-dark">
                <Accordion.Item eventKey={index.toString()}>
                    <Accordion.Header > <FaRegCalendarCheck />  -  <span style={{fontWeight:'600', textTransform:'capitalize'}}>{moment(historico.fecha).format("dddd DD MMMM YYYY")}</span></Accordion.Header>
                    <Accordion.Body key={index}>
                    <Row>
                    {historico.asistencias
                        .sort((a, b) => {
                            if (a.apellido < b.apellido) return -1;
                            if (a.apellido > b.apellido) return 1;
                            return a.nombre.localeCompare(b.nombre);
                        })
                        .map((asistencia, idx) => (
                        <Col sm={12} lg={6}  key={idx} className="mb-3">
                            <Row >
                                <ListGroup >
                                    <ListGroup.Item>
                                        <Row>
                                            <Col xs={8} sm={9} md={10} lg={9}><FaRegCircleUser /> <span style={{ fontWeight: 'bold' }}>{asistencia.apellido}, </span>{asistencia.nombre}</Col>
                                            <Col xs={4} sm={3} md={2} lg={3} className="text-end"> 
                                                <Form.Check
                                                    type="checkbox"
                                                    label={asistencia.presente ? 'Presente' : 'Ausente'}
                                                    checked={asistencia.presente}
                                                    onChange={(e) =>
                                                        handleModificarAsistencia(
                                                            asistencia.idAsistencia,
                                                            asistencia.idAlumno, 
                                                            historico.idCohorte, 
                                                            asistencia.presente
                                                        )}
                                                /> 
                                            </Col>
                                        </Row>
                                    </ListGroup.Item> 
                                </ListGroup>
                            </Row>                
                        </Col>
                    ))}
                </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            ))}
            <Button onClick={handleVolver} variant="dark" className="mt-4"><RiArrowGoBackFill /> Atrás</Button>
        </Container>
    )
}

export default AsistenciaHistorial;