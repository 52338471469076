import * as React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import cflService from '../service/cfl-service';
import moment from 'moment';
import { FaEdit,FaUserPlus } from 'react-icons/fa';
import { Button, Spinner,ProgressBar, Row,Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import FormAlumno from '../components/FormAlumnoEdit'
import Swal from 'sweetalert2';
import { FaTrashCan } from 'react-icons/fa6';


export default () => {
  const [alumnos, setAlumnos] = React.useState([])
  const [form, setForm] = React.useState(false)
  const [fila, setFila] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [progress, setProgress] = React.useState(20); 
  const navigate = useNavigate();
  

  const newAlumno=()=>{
    navigate("/crearalumno")
    console.log("boton")
  }

  const editRow = (row) => {
    let localidad = row.localidad;
    let nacionalidad = row.nacionalidad
    row.localidad = { label: localidad, value: localidad };
    row.nacionalidad = { label: nacionalidad, value: nacionalidad };
    row.provincia = { label: row.provincia, value: row.provincia };
    row.municipio = { label: row.partido, value: row.partido };
    row.fechaNacimiento = moment(row.fechaNacimiento).format("YYYY-MM-DD")
    setFila(row)
    setForm(true)
  }

  const deleteRow =async (data) => {
    Swal.fire({
        title: '¿Estás seguro/a? <br/> Esta acción es irreversible',
        html: `<p>Vas a desmatricular a: <b>${data.apellido}, ${data.nombre}</b> de forma permanente`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#198754',
        cancelButtonColor: '#d33',
        confirmButtonText: '¡Sí, borrar!',
        cancelButtonText:"Cancelar",
    }).then((result) => {
        if (result.isConfirmed) {
           
            Swal.fire(
                'Borrado!',
                `El alumno/a ${data.apellido}, ${data.nombre } ya no esta en la base de datos `,
                'success'
            )
            cflService.deleteAlumno(data.idAlumnos)
                .then((resp) => {
                  if(resp.data.success){
                    console.log("borrardo")
                   window.location.reload()
                  }
                })
                .catch((error) => console.log(error))
        }
    })
}

  const actionFormatter = (cell, row) => {
    return (
      <div className='accion-table-alumno'>
        <Button as='div' variant="primary" className='mr-2' onClick={() => editRow(row)}>
          <FaEdit />
        </Button>
        <Button as='div' variant="danger" onClick={() => deleteRow(row)}>
          <FaTrashCan />
        </Button>
      </div>
    );
  };
  

  React.useEffect(() => {
      setProgress(60)
    cflService.buscarTodosAlumnos()
      .then((res) => {
        console.log(res)
        // Asegúrate de convertir las fechas a objetos Date aquí si no lo están
        const alumnosConFechas = res.data.data.map((alumno) => ({
          ...alumno,
          fechaNacimiento: moment(alumno.fechaNacimiento).toDate(),
        }));
        setProgress(100); // Establecemos el progreso en 100% al finalizar la carga.
       
        setIsLoading(false)
        setAlumnos(alumnosConFechas);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: 'Error',
          text: "No se pudo obtener los datos",
          icon: 'warning'
        })

        setIsLoading(false)
      });
  }, []);

  const headerStyles = { backgroundColor: 'black', color: 'white' };
  const columns = [{
    dataField: 'idAlumnos',
    text: 'ID',
    headerStyle: {
      width: '90px', backgroundColor: 'black', color: 'white',  verticalAlign: 'middle'}  
  }, {
    dataField: 'nombre',
    text: 'Nombre',
    filter: textFilter({
      placeholder: 'Filtrar por Nombre', // Cambia el texto aquí
      text: 'Filtrar por Nombre',
    }),
    headerStyle: headerStyles,
  }, {
    dataField: 'apellido',
    text: 'Apellido',
    filter: textFilter({
      placeholder: 'Filtrar por Apellido', // Cambia el texto aquí
      text: 'Filtrar by Apellido',
    }),
    headerStyle: headerStyles,
  },
  {
    dataField: 'actions',
    text: 'Acciones',
    formatter: actionFormatter, // Usa la función formatter personalizada
    style: {
      width: '100px', // Establece el ancho de la columna en 100px
    },
    headerStyle: {
      width: '110px', backgroundColor: 'black', color: 'white', textAlign: 'center', verticalAlign: 'middle'// Establece el ancho del encabezado de la columna en 100px
    },
  },

  ];
  
    return (
      <>
        {isLoading && 
        
        <ProgressBar animated now={progress} style={{marginTop:"3em"}} label={`${progress} %`}/>
        
        }
        {!form && !isLoading && 
        <>
       <Row >
        <Col style={{display:"flex",justifyContent:"end"}}>
          <button style={{padding:"2em 3em 2em 0",borderColor:"transparent",backgroundColor:"transparent"}} onClick={newAlumno} title='Cargar nuevo alumno'>
          <FaUserPlus size={48} />
          </button>  
        </Col>
       </Row>
        
        <BootstrapTable
          keyField='idAlumnos'
          data={alumnos}
          columns={columns}
          pagination={paginationFactory()}
          filter={filterFactory()}
          rowStyle={(row, rowIndex) => ({
          backgroundColor: rowIndex % 2 === 0 ? '#f8f9fa' : '#ffffff', // Alterna colores
          borderLeft: 'none', // Sin borde vertical izquierdo
          borderRight: 'none', // Sin borde vertical derecho
          borderBottom: '1px solid #dee2e6', // Agrega un borde horizontal en la parte inferior
          borderTop: '1px solid #dee2e6', // Agrega un borde horizontal en la parte superior
        })}
        />
        </>
        }
        {form && !isLoading &&<FormAlumno initialValues={fila} />}
      </>
  )
  
  
}
