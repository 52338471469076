import axios from "axios"

const getProvinciasByNombre=(nombre)=>{
    return axios.get(`https://apis.datos.gob.ar/georef/api/provincias?nombre=${nombre}`)
}
const getAllProvincias=()=>{
    return  axios.get("https://apis.datos.gob.ar/georef/api/provincias")
}

const getMunicipiosByProvincia=(provincia,municipio)=>{
    return   axios.get(`https://apis.datos.gob.ar/georef/api/municipios?provincia=${provincia}&nombre=${municipio}`)
}

const getLocalidadByMunicipio=(municipio,provincia)=>{
    return axios.get(`https://apis.datos.gob.ar/georef/api/localidades?municipio=${municipio}&provincia=${provincia}`)
}

const getmunicipiosByProvincias=(provincia)=>{
    return axios.get(`https://apis.datos.gob.ar/georef/api/municipios?provincia=${provincia}&max=1000`)
}

const georef={
    getProvinciasByNombre,
    getAllProvincias,
    getMunicipiosByProvincia,
    getLocalidadByMunicipio,
    getmunicipiosByProvincias
}

export default georef